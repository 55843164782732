import { Period } from './Period'

export class ReasonScheduleRule {
    constructor(
        public id: string,
        public daysOfWeek: number[],
        public period: Period | null
    ) { }

    usedOnDate(date: Date): boolean {
        if (this.period !== null && !this.period.open) {
            return false
        }
        const dayOfWeekStartingOnSunday = date.getDay()
        const startingOnMonday = dayOfWeekStartingOnSunday === 0 ? 7 : dayOfWeekStartingOnSunday
        return this.daysOfWeek.includes(startingOnMonday)
    }

    usedOnDateTime(dateTime: Date): boolean {
        if (!this.usedOnDate(dateTime)) {
            return false
        }
        if (this.period && !this.period.containsTime(dateTime)) {
            return false
        }
        return true
    }
}
