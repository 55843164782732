import { ActivatedRoute } from '@angular/router'
import { Context } from '../domain/Context'
import { ContextService } from './ContextService'
import { Injectable } from '@angular/core'
import { Observable, filter, map } from 'rxjs'

@Injectable()
export class RouterContextService implements ContextService {

    constructor(private route: ActivatedRoute) {}

    context(): Observable<Context> {
        return this.route.paramMap.pipe(
            filter(paramMap => {
                if (!paramMap.has('organisationId')) {
                    return false
                }
                if (!paramMap.has('businessId')) {
                    return false
                }
                if (!paramMap.has('venueId')) {
                    return false
                }
                return true
            })
        )
            .pipe(
                map(paramMap => {
                    return new Context(
                        paramMap.get('organisationId')!,
                        paramMap.get('businessId')!,
                        paramMap.get('venueId')!
                    )
                })
            )
    }
}
