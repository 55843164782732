import { ActivatedRoute } from '@angular/router'
import { BookingDetails } from '../domain/BookingDetails'
import { BookingDetailsViewModel } from '../booking-summary/BookingDetailsViewModel'
import { BookingService } from '../services/booking.service'
import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core'
import {
    ConfirmCancellationComponent,
} from '../confirm-cancellation/confirm-cancellation.component'
import { ConfirmCardComponent } from '../confirm-payment/confirm-card.component'
import { GetDirectionsService } from '../services/get-directions.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import {
    Observable,
    Subject,
    filter,
    firstValueFrom,
    from, map, mergeMap, of, takeUntil,
} from 'rxjs'
import { WindowService } from '../services/window/window.service'

@Component({
    selector: 'app-booking-details',
    templateUrl: './booking-details.component.html',
})
export class BookingDetailsComponent implements OnInit, OnDestroy {

    @Input() viewModel!: Observable<BookingDetailsViewModel>
    @Output() bookAgainSelected = new Subject<void>()
    partySizePluralMapping = {
        '=1': '1 person',
        other: '# people',
    }
    private onDestroy$ = new Subject<boolean>()

    constructor(
        private bookingService: BookingService,
        private getDirectionService: GetDirectionsService,
        private modalService: NgbModal,
        private windowService: WindowService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.startCancelIfQueryParameterIncluded()
    }

    ngOnDestroy() {
        this.onDestroy$.next(true)
        this.onDestroy$.complete()
    }

    bookAgainClicked(_: BookingDetailsViewModel) {
        this.bookAgainSelected.next()
    }

    modifyBookingClicked(details: BookingDetails) {
        const url = details.modifyBookingPath
        if (url === null) {
            return
        }
        this.windowService.navigateToUrl(url)
    }

    getDirectionsClicked(viewModel: BookingDetailsViewModel) {
        const addressLines = viewModel.details.venueAddress.lines()
        const directionsURL = this.getDirectionService.createURL(addressLines)
        this.windowService.openWindowAtUrl(directionsURL)
    }

    viewBookingClicked(bookingDetails: BookingDetails) {
        this.windowService.openWindowAtUrl(`booking/${bookingDetails.bookingId}`)
    }

    cancelBookingClicked(viewModel: BookingDetailsViewModel) {
        if (!viewModel.showCancelButton) {
            return
        }
        const modal = this.modalService.open(ConfirmCancellationComponent)
        const component = modal.componentInstance as ConfirmCancellationComponent
        component.bookingDetails = viewModel.details
        component.cancelSelected
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
                modal.dismiss()
            })
        component.confirmSelected
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
                modal.close()
                this.cancelBooking()
            })
    }

    confirmWithCard(viewModel: BookingDetailsViewModel) {
        const modal = this.modalService.open(ConfirmCardComponent)
        const component = modal.componentInstance as ConfirmCardComponent
        component.bookingDetails = viewModel.details
        modal.closed
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((bookingDetails: BookingDetails) => {
                this.viewModel = of(this.makeViewModel(bookingDetails))
            })
    }

    private startCancelIfQueryParameterIncluded() {
        this.route.queryParams.pipe(
            takeUntil(this.onDestroy$),
            filter(params => params['action'] === 'cancel'),
            mergeMap(() => this.viewModel)
        )
            .subscribe(viewModel => this.cancelBookingClicked(viewModel))
    }

    private cancelBooking() {
        const cancel = firstValueFrom(this.viewModel.pipe(
            mergeMap(viewModel => this.bookingService.cancelBooking(viewModel.details.bookingId))
        ))
        this.viewModel = from(cancel).pipe(
            takeUntil(this.onDestroy$),
            map(details => this.makeViewModel(details))
        )
    }

    private makeViewModel(details: BookingDetails): BookingDetailsViewModel {
        return new BookingDetailsViewModel(details, false, false)
    }

    protected readonly ConfirmCardComponent = ConfirmCardComponent
}
