<div class="container col-sm-10 col-md-8 col-lg-6 col-xl-4 my-2">
    <form
        [formGroup]="form"
    >
        <div class="vstack gap-3">
            <div class="hstack gap-3 gap-lg-4">
                <div class="flex-shrink-0">
                    <label class="form-label">Party</label>
                    <div
                        class="d-flex"
                        ngbDropdown
                    >
                        <button
                            class="btn btn-outline-secondary flex-grow-1"
                            id="partySize"
                            ngbDropdownToggle
                        >
                            <span
                                *ngIf="form.get('partySize')?.value === minLargePartySize"
                            >
                                {{ minLargePartySize }}+
                            </span>
                            <span
                                *ngIf="form.get('partySize')?.value !== minLargePartySize"
                            >
                                {{ form.get('partySize')?.value }}
                            </span>
                        </button>
                        <div
                            class="dropdown-menu"
                            aria-labelledby="partySize"
                            ngbDropdownMenu
                        >
                            <button
                                class="dropdown-item"
                                *ngFor="let size of possiblePartySizes"
                                (click)="partySizeSelected(size)"
                            >
                                {{ size }}
                            </button>
                            <button
                                *ngIf="minLargePartySize"
                                class="dropdown-item"
                                (click)="partySizeSelected(minLargePartySize)"
                            >
                                {{ minLargePartySize }}+
                            </button>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <label class="form-label">Date</label>
                    <div class="d-flex">
                        <input
                            #datePicker="ngbDatepicker"
                            class="form-control p-0 m-0 border-0"
                            formControlName="selectedDate"
                            ngbDatepicker
                            style="width: 0; height: 0"
                            navigation="arrows"
                            placement="bottom-start"
                            [minDate]="today"
                        />
                        <button
                            class="btn btn-outline-secondary dropdown-toggle flex-grow-1"
                            (click)="datePicker.toggle()"
                        >
                            {{ form.get('selectedDate')?.value | ngbDate | relativeDate: 'mediumDate' }}
                        </button>
                    </div>
                </div>
                <div class="flex-shrink-0">
                    <label class="form-label">Time</label>
                    <div
                        class="btn-group d-flex"
                        ngbDropdown
                    >
                        <button
                            class="btn btn-outline-secondary flex-grow-1"
                            id="time"
                            ngbDropdownToggle
                        >
                            {{ form.get('selectedTime')?.value | date: 'shortTime' }}
                        </button>
                        <div
                            class="dropdown-menu"
                            aria-labelledby="time"
                            ngbDropdownMenu
                        >
                            <button
                                class="dropdown-item"
                                *ngFor="let time of possibleTimes"
                                (click)="timeSelected(time)"
                            >
                                {{ time | date: 'shortTime' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                *ngIf="areas.length > 0 && reasons.length > 0 && !hasSelectedALargePartySize"
                class="vstack"
            >
                <label class="form-label">
                    Select a reason for your visit:
                </label>
                <div class="hstack gap-2">
                    <div
                        *ngFor="let reason of reasons"
                    >
                        <input
                            type="radio"
                            class="btn-check"
                            id="{{ reason.id }}"
                            autocomplete="off"
                            formControlName="selectedReasonId"
                            [value]="reason.id"
                        >
                        <label
                            class="btn btn-outline-secondary"
                            for="{{ reason.id }}"
                        >
                            {{ reason.displayName }}
                        </label>
                    </div>
                </div>
                <span
                    *ngIf="selectedReason?.description"
                    class="fw-semibold pt-2"
                >
                    {{ selectedReason?.description }}
                </span>

            </div>
            <div
                *ngIf="areas.length > 0 && !hasSelectedALargePartySize"
            >
                <label class="form-label">
                    Select an area:
                </label>
                <div class="hstack gap-2">
                    <div
                        *ngFor="let area of areas"
                    >
                        <input
                            type="radio"
                            class="btn-check"
                            id="{{ area.id }}"
                            autocomplete="off"
                            formControlName="selectedAreaId"
                            [value]="area.id"
                        >
                        <label
                            class="btn btn-outline-secondary"
                            for="{{ area.id }}"
                        >
                            {{ area.displayName }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <label
                    *ngIf="!venueIsClosed && !hasSelectedALargePartySize"
                    class="form-label"
                >
                    Select a time:
                </label>
                <ng-container
                    *ngIf="possibleBookingSlots.length > 0 && !hasSelectedALargePartySize"
                >
                    <div
                        *ngFor="let bookingSlotAvailability of possibleBookingSlots"
                        class="col-4 pb-2"
                    >
                        <fieldset
                            [disabled]="!bookingSlotAvailability.isReservable"
                            class="btn btn-primary p-0 w-100"
                        >
                            <input
                                type="radio"
                                class="btn-check"
                                id="{{ bookingSlotAvailability.bookingSlot.bookingSlot.dateTime }}"
                                autocomplete="off"
                                formControlName="bookingSlot"
                                [value]="bookingSlotAvailability.bookingSlot.bookingSlot"
                            >
                            <label
                                for="{{ bookingSlotAvailability.bookingSlot.bookingSlot.dateTime }}"
                                class="p-2 w-100"
                                role="button"
                            >
                                {{ bookingSlotAvailability.bookingSlot.bookingSlot.dateTime | date: 'shortTime' }}
                            </label>
                        </fieldset>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="vstack gap-2">
            <div
                *ngIf="possibleBookingSlots.length === 0 && !needsToSelectReason && !needsToSelectArea"
                class="text-center fw-semibold p-3"
            >
                Sorry, we're closed at this time.
            </div>
            <div
                *ngIf="possibleBookingSlots.length === 0 && needsToSelectReason"
                class="text-center fw-semibold p-3"
            >
                Please select a reason for your visit to see our opening times.
            </div>
            <div
                *ngIf="possibleBookingSlots.length === 0 && needsToSelectArea"
                class="text-center fw-semibold p-3"
            >
                Please select an area to see our opening times.
            </div>
            <div
                *ngIf="hasSelectedALargePartySize"
                class="text-center fw-semibold p-3"
            >
                To ensure the best possible experience, we ask that you get in touch at
                <a [href]="availability.venue.phoneNumber"> {{ availability.venue.phoneNumber }}</a>
                <ng-container
                    *ngIf="availability.venue.emailAddress"
                >
                or
                <a [href]="'mailto:' + availability.venue.emailAddress"> {{ availability.venue.emailAddress }}</a>
                </ng-container>
                to make your booking.
                <br/>
                We look forward to seeing you soon.
            </div>
            <div
                *ngIf="allPossibleBookingSlotsUnavailable && !hasSelectedALargePartySize"
                class="text-center fw-semibold p-3"
            >
                Sorry, we don't have any tables available for your party size at this time.
                <br>
                Try a different time or party size,
                or get in touch at
                <a [href]="availability.venue.phoneNumber"> {{ availability.venue.phoneNumber }}</a>
                to see if we can help.
            </div>
        </div>
    </form>
</div>
