<app-spinner-overlay
    [showSpinner]="isSaving"
>
    <div
        *ngIf="formError"
        class="alert alert-danger"
    >
        {{ formError }}
    </div>
    <form
        appVenueColour
        [primaryColour]="venue?.primaryColour"
        class="h-100 vstack gap-3"
    >
        <div class="vstack gap-2">
            <h5
                *ngIf="showTitle"
            >
                Deposit
            </h5>
            <div class="vstack gap-2">
                <label class="text-muted">
                    We require a deposit of
                    <span class="fw-bold">
                        {{ amount.toFormat() }}
                    </span>
                    to book your table.
                    <span
                        *ngIf="refundCutOffDays"
                    >
                        Cancellations or no-shows with less than
                        {{ refundCutOffDays | i18nPlural : daysPluralMapping }}
                        notice will not be refunded.
                    </span>
                    <span
                        *ngIf="!refundCutOffDays"
                    >
                        Cancellations or no-shows will not be refunded.
                    </span>
                </label>
                <div
                    id="payment-intent"
                >
                </div>
            </div>
        </div>
        <div class="vstack justify-content-end">
            <div class="hstack gap-2">
                <button
                    *ngIf="showBackButton"
                    type="button"
                    class="btn btn-secondary w-auto"
                    (click)="onBackSelected()"
                >
                    Back
                </button>
                <div
                    *ngIf="showBackButton"
                    class="flex-grow-1 ms-auto me-auto"
                >
                </div>
                <button
                    type="button"
                    class="btn btn-primary"
                    [ngClass]="showBackButton ? 'w-auto' : 'flex-grow-1'"
                    [disabled]="!cardDetailsAreComplete"
                    (click)="onBookSelected()"
                >
                    Book
                </button>
            </div>
        </div>
    </form>
</app-spinner-overlay>
