import { Injectable } from '@angular/core'
import { NgbDatepickerI18nDefault } from '@ng-bootstrap/ng-bootstrap'

@Injectable()
export class GBTimeZoneDatepickerI18n extends NgbDatepickerI18nDefault {

    private staticWeekdayLabels = [
        'M',
        'T',
        'W',
        'T',
        'F',
        'S',
        'S',
    ]

    override getWeekdayLabel(
        weekday: number,
        width?: Exclude<Intl.DateTimeFormatOptions['weekday'], undefined>
    ): string {
        return this.staticWeekdayLabels[ (weekday - 1) % 7 ]
    }
}
