import { Helper } from '../utilities/helper'

export class ReasonDateRangeFilter {
    constructor(
        public id: string,
        public startDate: Date,
        public endDate: Date
    ) { }

    usedOnDate(date: Date): boolean {
        return date >= Helper.startOfDay(this.startDate) && date <= Helper.endOfDay(this.endDate)
    }
}
