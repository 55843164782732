<app-spinner-overlay [showSpinner]="isBooking">
    <div class="container col-sm-10 col-md-8 col-lg-6 col-xl-4 my-2">
        <form
            [formGroup]="form"
            class="vstack gap-3"
        >
            <div class="vstack gap-2">
                <h5>Your Details</h5>
                <div class="row">
                    <div class="col">
                        <input
                            id="inputFirstName"
                            class="form-control"
                            formControlName="firstName"
                            type="text"
                            placeholder="First Name"
                            [ngClass]="{
                                'is-invalid': form.get('firstName')!!.invalid && form.get('firstName')!!.touched
                            }"
                        />
                    </div>
                    <div class="col">
                        <input
                        id="inputLastName"
                        class="form-control"
                        formControlName="lastName"
                        type="text"
                        placeholder="Last Name"
                    />
                    </div>
                </div>
                <div>
                    <input
                        id="inputEmailAddress"
                        class="form-control"
                        formControlName="emailAddress"
                        type="text"
                        placeholder="Email Address"
                        [ngClass]="{
                            'is-invalid': form.get('emailAddress')!!.invalid && form.get('emailAddress')!!.touched
                        }"
                    />
                </div>
                <div>
                    <input
                        id="inputPhoneNumber"
                        class="form-control"
                        formControlName="phoneNumber"
                        type="text"
                        placeholder="Phone Number"
                        [ngClass]="{
                            'is-invalid': form.get('phoneNumber')!!.invalid && form.get('phoneNumber')!!.touched
                        }"
                    />
                </div>
                <div>
                    <label for="inputNotes" class="form-label">Booking Notes (optional)</label>
                    <textarea
                        class="form-control"
                        placeholder=""
                        id="inputNotes"
                        formControlName="notes"
                        style="height: 100px"
                    >
                    </textarea>
                </div>
            </div>

            <div class="vstack gap-2">
                <ng-container formArrayName="questions">
                    <ng-container
                        *ngFor="let questionControl of questions.controls; let i = index"
                        [formGroupName]="i"
                    >
                        <ng-container
                            *ngIf="questionControl.get('question')!!.value as question"
                        >
                            <div
                                *ngIf="question.type === QuestionType.Checkbox"
                                class="form-check"
                            >
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    [id]="'question-' + i"
                                    formControlName="answer"
                                    [ngClass]="{
                                        'is-invalid': questionControl.get('answer')!!.invalid && questionControl.get('answer')!!.touched
                                    }"
                                >
                                <label
                                    class="form-check-label"
                                    [for]="'question-' + i"
                                >
                                    {{ question.text }}
                                    <span
                                        *ngIf="questionControl.get('answer')!!.invalid && questionControl.get('answer')!!.touched"
                                        class="text-danger"
                                    >
                                        (Required)
                                    </span>
                                </label>
                            </div>
                            <div
                                *ngIf="question.type === QuestionType.AcknowledgeEndTime"
                                class="form-check"
                            >
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    [id]="'question-' + i"
                                    formControlName="answer"
                                    [ngClass]="{
                                        'is-invalid': questionControl.get('answer')!!.invalid && questionControl.get('answer')!!.touched
                                    }"
                                >
                                <label
                                    class="form-check-label"
                                    [for]="'question-' + i"
                                >
                                    I acknowledge that the booking end time is {{ form.get('endTime')!!.value }}
                                    <span
                                        *ngIf="questionControl.get('answer')!!.invalid && questionControl.get('answer')!!.touched"
                                        class="text-danger"
                                    >
                                        (Required)
                                    </span>
                                </label>
                            </div>
                            <div
                                *ngIf="question.type === QuestionType.Dropdown"
                            >
                                <label
                                    for="question-{{ i }}"
                                    class="form-label"
                                >
                                    {{ question.text }}
                                </label>
                                <div
                                    ngbDropdown
                                >
                                    <button
                                        class="btn btn-outline-secondary"
                                        id="question-{{ i }}"
                                        ngbDropdownToggle
                                        [ngClass]="{
                                            'is-invalid': questionControl.get('answer')!!.invalid && questionControl.get('answer')!!.touched
                                        }"
                                    >
                                        <span *ngIf="!questionControl.get('answer')!!.value && !question.required">
                                            No Selection
                                        </span>
                                        <span *ngIf="!questionControl.get('answer')!!.value && question.required">
                                            Required
                                        </span>
                                        <span *ngIf="questionControl.get('answer')!!.value">
                                            {{ questionControl.get('answer')!!.value }}
                                        </span>
                                    </button>
                                    <div ngbDropdownMenu>
                                        <button
                                            *ngIf="!question.required"
                                            class="dropdown-item hstack gap-2 align-items-baseline justify-content-between"
                                            (click)="questionControl.get('answer')!!.setValue(null)"
                                        >
                                            No Selection
                                            <i
                                                *ngIf="!questionControl.get('answer')!!.value"
                                                class="bi bi-check-circle"
                                            >
                                            </i>
                                        </button>
                                        <button
                                            *ngFor="let option of question.options"
                                            class="dropdown-item hstack gap-2 align-items-baseline justify-content-between"
                                            (click)="questionControl.get('answer')!!.setValue(option)"
                                        >
                                            {{ option }}
                                            <i
                                               *ngIf="questionControl.get('answer')!!.value === option"
                                                class="bi bi-check-circle"
                                            >
                                            </i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
            <div class="vstack gap-2">
                <div class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="agreedToMarketing"
                        formControlName="agreedToMarketing"
                    >
                    <label class="form-check-label" for="agreedToMarketing">
                        I would like to receive news and offers from {{ venueName }}
                    </label>
                </div>
                <div class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="agreedToTerms"
                        formControlName="agreedToTerms"
                        [ngClass]="{
                            'is-invalid': form.get('agreedToTerms')!!.invalid && form.get('agreedToTerms')!!.touched
                        }"
                    >
                    <label class="form-check-label" for="agreedToTerms">
                        I have read and accept the TableSense
                        <a class="primary" [href]="privacyPolicyURL" target="_blank">Privacy Policy&nbsp;
                            <i class="bi bi-box-arrow-up-right"></i>
                        </a> agreement
                        <span
                            *ngIf="form.get('agreedToTerms')!!.invalid && form.get('agreedToTerms')!!.touched"
                            class="text-danger"
                        >
                            (Required)
                        </span>
                    </label>
                </div>
            </div>

            <div class="row">
                <div class="col-auto me-auto">
                    <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="onBackSelected()"
                    >
                        Back
                    </button>
                </div>
                <div class="col-auto">
                    <button
                        *ngIf="form.get('paymentDetailsRequired')!!.value || form.get('depositRequired')!!.value"
                        type="button"
                        class="btn btn-secondary"
                        (click)="onNextSelected()"
                    >
                        Next
                    </button>
                    <button
                        *ngIf="!form.get('paymentDetailsRequired')!!.value && !form.get('depositRequired')!!.value"
                        type="button"
                        class="btn btn-primary"
                        (click)="onBookSelected()"
                    >
                        Book
                    </button>
                </div>
            </div>
        </form>
    </div>
</app-spinner-overlay>
