<div class="text-center fw-semibold p-3">
    <ng-container
        *ngIf="availability.noAvailabilityDescription(areaId); let noAvailabilityDescription"
    >
        <span [innerHTML]="noAvailabilityDescription"></span>
    </ng-container>
    <ng-container
        *ngIf="!availability.noAvailabilityDescription(areaId)"
    >
        Sorry, we don't have any tables available for your party size at this time.
        <br>
        Try a different time or party size,
        or get in touch at
        <a [href]="availability.venue.phoneNumber"> {{ availability.venue.phoneNumber }}</a>
        to see if we can help.
    </ng-container>
</div>
