import { ActivatedRoute } from '@angular/router'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subject, takeUntil } from 'rxjs'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {

    private onDestroy$ = new Subject<void>()

    constructor(
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.bindScaleQueryParameterToBodyTransform()
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    private bindScaleQueryParameterToBodyTransform() {
        this.route.queryParamMap
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe(queryParamMap => {
                const scale = queryParamMap.get('scale')
                if (!scale) {
                    return
                }
                document.body.style.transform = `scale(${scale})`
            })
    }
}
