<div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
        <div class="spinner-wrapper">
            <div class="spinner-border" role="status"></div>
        </div>
    </div>

    <div class="h-100" [class.blurred]="showSpinner">
        <ng-content></ng-content>
    </div>
</div>
