import { BookingDetails } from '../domain/BookingDetails'
import { BookingService } from '../services/booking.service'
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Subject, takeUntil } from 'rxjs'
import { Venue } from '../domain/Venue'
import { VenueService } from '../services/venue.service'

@Component({
    selector: 'app-confirm-card',
    templateUrl: './confirm-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmCardComponent implements OnInit, OnDestroy {

    @Input() bookingDetails!: BookingDetails
    form!: FormGroup
    venue: Venue | null = null
    isLoadingVenue = false
    isConfirmingPayment = false
    errorLoadingVenue = false
    errorConfirmingPayment = false
    private onDestroy$ = new Subject<void>()

    constructor(
        private bookingService: BookingService,
        private changeDetector: ChangeDetectorRef,
        private fb: FormBuilder,
        private modal: NgbActiveModal,
        private venueService: VenueService
    ) { }

    ngOnInit() {
        this.form = this.makeForm()
        this.loadVenue()
        this.bindCancellationChargePaymentMethodIdToConfirmingPayment()
        this.bindDepositPaymentIntentIdToConfirmingPayment()
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    dismiss() {
        this.modal.dismiss()
    }

    confirmWithCardStarted() {
        this.errorConfirmingPayment = false
        this.changeDetector.detectChanges()
    }

    private makeForm() {
        return this.fb.group({
            'PaymentDetails': this.fb.group({
                paymentMethodId: [null, []],
            }),
            'Deposit': this.fb.group({
                paymentIntentId: [null, []],
            }),
        })
    }

    private loadVenue() {
        this.isLoadingVenue = true
        this.errorLoadingVenue = false
        this.changeDetector.detectChanges()
        this.venueService.getWithId(this.bookingDetails.venueId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: venue => {
                    this.venue = venue
                    this.isLoadingVenue = false
                    this.changeDetector.detectChanges()
                },
                error: () => {
                    this.errorLoadingVenue = true
                    this.isLoadingVenue = false
                    this.changeDetector.detectChanges()
                },
            })
    }

    private bindCancellationChargePaymentMethodIdToConfirmingPayment() {
        this.form.get('PaymentDetails.paymentMethodId')?.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (paymentMethodId) => {
                    if (paymentMethodId) {
                        this.confirmPayment(paymentMethodId, null)
                    }
                },
            })
    }

    private bindDepositPaymentIntentIdToConfirmingPayment() {
        this.form.get('Deposit.paymentIntentId')?.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (paymentIntentId) => {
                    if (paymentIntentId) {
                        this.confirmPayment(null, paymentIntentId)
                    }
                },
            })
    }

    private confirmPayment(
        cancellationChargePaymentMethodId: string | null,
        depositPaymentIntentId: string | null
    ) {
        this.isConfirmingPayment = true
        this.errorConfirmingPayment = false
        this.changeDetector.detectChanges()
        this.bookingService.confirmBooking(
            this.bookingDetails.bookingId,
            cancellationChargePaymentMethodId,
            depositPaymentIntentId
        )
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (bookingDetails) => {
                    this.isConfirmingPayment = false
                    this.modal.close(bookingDetails)
                    this.changeDetector.detectChanges()
                },
                error: () => {
                    this.isConfirmingPayment = false
                    this.errorConfirmingPayment = true
                    this.changeDetector.detectChanges()
                },
            })
    }
}
