import { BookingReason } from '../domain/BookingReason'
import { Period } from '../domain/Period'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'reasonTime',
    standalone: true,
})
export class ReasonTimePipe implements PipeTransform {

    transform(reason: BookingReason, date: Date): string | null {
        if (reason.scheduleRules.length === 0) {
            return 'All day'
        }
        const matchingRules = reason.scheduleRules
            .filter(rule => rule.usedOnDate(date))
        if (matchingRules.length === 0) {
            return null
        }
        const periods = matchingRules
            .map(rule => rule.period)
            .filter(period => period !== null) as Period[]
        return Period.combineAdjacentPeriods(periods, date)
            .map(period => period.toString())
            .join(', ')
    }
}
