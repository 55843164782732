import { Area } from '../domain/Area'
import { AreaAvailability } from '../domain/AreaAvailability'
import { Availability } from '../domain/Availability'
import { BookingReason } from '../domain/BookingReason'
import { BookingSlot } from '../domain/BookingSlot'
import { BookingSlotOption } from '../domain/BookingSlotOption'
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms'
import { Event } from '../domain/Event'
import { NGBDateTimeAdapter } from '../utilities/NgbDateTimeAdapter'
import { NgbDateNativeAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct'
import { Venue } from '../domain/Venue'

class BookingSlotSelectionOption {

    public date: NgbDateStruct
    public start: NgbTimeStruct
    public end: NgbTimeStruct | null = null
    public reservableArea: Area | null

    constructor(
        private venue: Venue,
        private areaAvailability: AreaAvailability[],
        private ngbDateAdapter: NgbDateNativeAdapter,
        private ngbTimeAdapter: NGBDateTimeAdapter,
        public bookingSlot: BookingSlot,
        public reason: BookingReason | null,
        public event: Event | null,
        public partySize: number
    ) {
        this.date = this.ngbDateAdapter.fromModel(bookingSlot.dateTime)!
        this.start = this.ngbTimeAdapter.fromModel(bookingSlot.dateTime)!
        const firstReservableArea = this.firstReservableArea()
        this.reservableArea = firstReservableArea ? firstReservableArea.area : null
        if (firstReservableArea === null) {
            return
        }
        const option = this.reservableOption(firstReservableArea)
        if (option === null) {
            return
        }
        const endDateTime = bookingSlot.endDateTime(option.durationMinutes)
        this.end = endDateTime ? this.ngbTimeAdapter.fromModel(endDateTime) : null
    }

    get isReservable() {
        return this.reservableArea !== null
    }

    get isAfterCutOffTime() {
        if (this.reservableArea === null) {
            return false
        }
        return this.bookingSlot.dateTime.getTime() >= this.reservableArea.cutOffTime.getTime()
    }

    private firstReservableArea(): AreaAvailability | null {
        const firstReservableArea = this.areaAvailability
            .sort((a, b) => {
                if (this.reason === null) {
                    const areaBookingOrder = this.venue.areaBookingOrder
                    if (areaBookingOrder.length === 0) {
                        return 0
                    }
                    const aOrder = areaBookingOrder.findIndex(id => id === a.area.id)
                    const bOrder = areaBookingOrder.findIndex(id => id === b.area.id)
                    return aOrder - bOrder
                }
                const areaBookingOrder = this.reason.areaBookingOrder
                if (areaBookingOrder === null) {
                    return 0
                }
                const aOrder = areaBookingOrder.findIndex(id => id === a.area.id)
                const bOrder = areaBookingOrder.findIndex(id => id === b.area.id)
                return aOrder - bOrder
            })
            .find(areaAvailability => this.reservableOption(areaAvailability) !== null)
        return firstReservableArea ?? null
    }

    private reservableOption(areaAvailability: AreaAvailability): BookingSlotOption | null {
        return areaAvailability.reservableOptionWithConfiguration(
            this.partySize,
            this.reason,
            this.event,
            this.bookingSlot.dateTime
        )
    }
}

@Component({
    selector: 'app-booking-slot-selection',
    templateUrl: './booking-slot-selection.component.html',
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
        },
    ],
})
export class BookingSlotSelectionComponent implements OnInit, OnChanges {

    @Input() areaId!: string
    @Input() reasonId!: string | null
    @Input() eventId!: string | null
    @Input() partySize!: number
    @Input({ transform: (value: number) => new Date(value) }) idealTime!: Date
    @Input() availability!: Availability
    form!: FormGroup
    options: BookingSlotSelectionOption[] = []
    pageConfig = {
        itemsPerPage: 9,
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
    }
    private ngbTimeAdapter = new NGBDateTimeAdapter()
    private ngbDateAdapter = new NgbDateNativeAdapter()

    constructor(
        private rootFormGroup: FormGroupDirective
    ) { }

    ngOnInit() {
        this.form = this.rootFormGroup.form
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['availability']) {
            this.updateOptions()
        }
    }

    selectOption(option: BookingSlotSelectionOption) {
        if (option.reservableArea === null) {
            return
        }
        this.form.get('selectedAreaId')?.setValue(option.reservableArea.id)
        this.form.get('time')?.setValue(option.start)
        this.form.get('endTime')?.setValue(option.end)
    }

    private updateOptions() {
        const reason = this.reasonId ? this.availability.venue.reasonWithId(this.reasonId) : null
        const event = this.eventId ? this.availability.venue.eventWithId(this.eventId) : null
        const reservableAreas = this.availability.areas.filter(area => {
            if (this.areaId !== null) {
                return area.area.id === this.areaId
            } else if (reason !== null) {
                return area.isConfiguredForReason(reason)
            } else {
                return true
            }
        })
        if (reservableAreas.length === 0) {
            return
        }
        const rangeOfBookingSlots = reservableAreas
            .map(areaAvailability => areaAvailability.bookingSlots)
            .flat()
            .map(bookingSlotAvailability => bookingSlotAvailability.bookingSlot)
            .flat()
            .filter((bookingSlot, index, array) => {
                return array.findIndex(other => other.equals(bookingSlot)) === index
            })
        this.options = rangeOfBookingSlots
            .map(bookingSlot => {
                return new BookingSlotSelectionOption(
                    this.availability.venue,
                    reservableAreas,
                    this.ngbDateAdapter,
                    this.ngbTimeAdapter,
                    bookingSlot,
                    reason,
                    event,
                    this.partySize
                )
            })
            .filter(option => option.isAfterCutOffTime)
        // get index of booking slot closest to ideal time
        const index = this.options
            .map(option => {
                const dateTime = this.ngbTimeAdapter.toModel(option.start)!
                dateTime.setFullYear(this.idealTime.getFullYear(), this.idealTime.getMonth(), this.idealTime.getDate())
                return Math.abs(dateTime.getTime() - this.idealTime.getTime())
            })
            .reduce((prev, curr, index, array) => {
                return curr < array[prev] ? index : prev
            }, 0)
        const perPage = 9
        this.pageConfig.totalPages = Math.ceil(this.options.length / perPage)
        this.pageConfig.currentPage = Math.ceil(index / perPage)
        this.pageConfig.totalItems = this.options.length
    }
}
