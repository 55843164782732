import { Table } from './Table'

export class Area {
    constructor(
        public id: string,
        public displayName: string,
        public description: string | null,
        public displayOrder: number,
        public preBookingWindowMinutes: number,
        public cutOffTime: Date,
        public tables: Table[]
    ) { }

    minimumReservableSeats(): number {
        return Math.min(
            ...this.tables
                .filter(table => table.reservable)
                .map(table => table.minimumSeats)
        )
    }

    maximumReservableSeats(): number {
        return Math.max(
            ...this.tables
                .filter(table => table.reservable)
                .map(table => table.maximumSeats)
        )
    }

    hasAReservableTable(): boolean {
        return this.tables.find(table => table.reservable) !== undefined
    }

    hasAWheelchairRestrictedTable(): boolean {
        return this.tables.find(table => table.wheelchairAccessible === false) !== undefined
    }
}
