type RGB = [number, number, number];
type HSL = [number, number, number];

function rgbToHsl(rgb: RGB): HSL {
    let r = rgb[0] / 255, g = rgb[1] / 255, b = rgb[2] / 255
    let max = Math.max(r, g, b), min = Math.min(r, g, b)
    let h, s, l: number = (max + min) / 2
    h = s = l
    if (max === min) {
        h = s = 0
    } else {
        let d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
        case r:
            h = (g - b) / d + (g < b ? 6 : 0)
            break
        case g:
            h = (b - r) / d + 2
            break
        case b:
            h = (r - g) / d + 4
            break
        }
        h /= 6
    }
    return [h, s, l]
}

function hslToRgb(hsl: HSL): RGB {
    let h = hsl[0], s = hsl[1], l = hsl[2]
    let r, g, b
    if (s === 0) {
        r = g = b = l
    } else {
        function hue2rgb(p: number, q: number, t: number) {
            if (t < 0) {
                t += 1
            }
            if (t > 1) {
                t -= 1
            }
            if (t < 1 / 6) {
                return p + (q - p) * 6 * t
            }
            if (t < 1 / 2) {
                return q
            }
            if (t < 2 / 3) {
                return p + (q - p) * (2 / 3 - t) * 6
            }
            return p
        }
        let q = l < 0.5 ? l * (1 + s) : l + s - l * s
        let p = 2 * l - q
        r = hue2rgb(p, q, h + 1 / 3)
        g = hue2rgb(p, q, h)
        b = hue2rgb(p, q, h - 1 / 3)
    }
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
}

export function adjustLightness(color: string, percent: number): string {
    let rgb = color.split(',').map(value => parseInt(value.trim())) as RGB
    let hsl = rgbToHsl(rgb)
    hsl[2] = hsl[2] + hsl[2] * percent
    hsl[2] = Math.min(Math.max(0, hsl[2]), 1)
    let newRGB = hslToRgb(hsl)
    return newRGB.join(', ')
}
