import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { ContextService } from '../services/ContextService'
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms'
import { FormStep } from '../booking-form/booking-form.component'
import { QuestionType } from '../domain/Question'
import { RouterContextService } from '../services/RouterContextService'
import { Venue } from '../domain/Venue'
import { environment } from '../../environments/environment'
import { markAllFormControlsAsTouched } from '../utilities/form-touched'

@Component({
    selector: 'app-booking-details-form',
    templateUrl: './booking-details-form.component.html',
    providers: [{
        provide: ContextService,
        useClass: RouterContextService,
    }],
})
export class BookingDetailsFormComponent implements OnInit {
    @Output() backSelected = new EventEmitter<void>()
    @Output() nextSelected = new EventEmitter<void>()
    @Output() bookSelected = new EventEmitter<void>()

    form!: FormGroup
    venueName!: string
    privacyPolicyURL = environment.privacyPolicyURL
    isBooking = false
    protected readonly QuestionType = QuestionType

    constructor(
        private rootFormGroup: FormGroupDirective
    ) { }

    ngOnInit() {
        let formName = FormStep[FormStep.BookingDetails]
        this.form = this.rootFormGroup.control.get(formName) as FormGroup
        const venue: Venue = this.rootFormGroup.control
            .get('BookingSlot')
            ?.get('availability')
            ?.value
            .venue
        if (venue === null) {
            return
        }
        this.venueName = venue.displayName
    }

    get questions() {
        return this.form.get('questions') as FormArray
    }

    onBackSelected() {
        this.backSelected.emit()
    }

    onNextSelected() {
        markAllFormControlsAsTouched(this.form)
        if (this.form.invalid) {
            return
        }
        this.nextSelected.emit()
    }

    async onBookSelected() {
        markAllFormControlsAsTouched(this.form)
        if (this.form.invalid) {
            return
        }
        this.isBooking = true
        try {
            this.bookSelected.emit()
        } catch (_) {
            this.isBooking = false
        }
    }
}
