import { Time } from './Time'

export class Period {
    constructor(
        public start: Time,
        public end: Time,
        public open: boolean
    ) { }

    containsTime(time: Date): boolean {
        if (!this.open) {
            return false
        }
        const start = new Date(time)
        start.setHours(this.start.hours, this.start.minutes, 0, 0)
        const end = new Date(time)
        end.setHours(this.end.hours, this.end.minutes, 0, 0)
        return time >= start && time <= end
    }

    static combineAdjacentPeriods(periods: Period[], date: Date): Period[] {
        if (periods.length === 0) {
            return []
        }
        const sortedPeriods = periods
            .sort((a, b) => {
                return a.start.dateTimeOnDate(date).getTime() -
                    b.start.dateTimeOnDate(date).getTime()
            })
        const combinedPeriods: Period[] = []
        let currentPeriod = sortedPeriods[0]
        for (let i = 1; i < sortedPeriods.length; i++) {
            const nextPeriod = sortedPeriods[i]
            if (currentPeriod.end.equals(nextPeriod.start)) {
                currentPeriod = new Period(currentPeriod.start, nextPeriod.end, true)
            } else {
                combinedPeriods.push(currentPeriod)
                currentPeriod = nextPeriod
            }
        }
        combinedPeriods.push(currentPeriod)
        return combinedPeriods
    }

    toString(): string {
        return `${this.start.toString()} - ${this.end.toString()}`
    }
}
