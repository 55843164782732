export enum BookingStatusType {
    Booked = 'Booked',
    Cancelled = 'Cancelled',
    NoShow = 'NoShow',
    Seated = 'Seated',
    Finished = 'Finished',
    Rejected = 'Rejected',
    Requested = 'Requested',
    PendingPayment = 'PendingPayment',
}
