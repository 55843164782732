export const environment = {
    production: false,
    apiBaseUrl: 'https://backend.staging.tablesense.com',
    cdnUrl: 'https://d1ytssun2732e8.cloudfront.net',
    landingPageBaseUrl: 'https://book.staging.tablesense.com',
    marketingUrl: 'https://www.tablesense.com/?utm_source=customer_website&utm_medium=booking_channel&utm_campaign=customer_booking',
    newRelicApplicationId: '',
    privacyPolicyURL: 'https://tablesense.com/privacy-policy',
    getDirectionsURL: 'https://www.google.com/maps/search/?api=1&query=',
    stripePublishableKey: 'pk_test_51LwP0cEM5WnAQPYGeIR7UNiJs2HDe5XhBM4iqa05NzPP3CoCXfrmbsBDgcpgOheIZYEtLQ9qLEieCKohygVbNjRM00MQ9ZfOJh',
}
