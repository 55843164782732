import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
    booleanAttribute, numberAttribute,
} from '@angular/core'
import { Availability } from '../domain/Availability'
import { AvailabilityService } from '../services/availability.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Helper } from '../utilities/helper'
import { NGBDateTimeAdapter } from '../utilities/NgbDateTimeAdapter'
import { NgbDateNativeAdapter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { Observable, Subject, finalize, takeUntil, tap } from 'rxjs'
import { Time } from '../domain/Time'
import { environment } from '../../environments/environment'
import { GBTimeZoneDatepickerI18n } from '../services/gb-time-zone-datepicker-i18n.service'

@Component({
    selector: 'app-booking-modal-launcher',
    templateUrl: './booking-modal-launcher.component.html',
    providers: [
        {
            provide: NgbDatepickerI18n,
            useClass: GBTimeZoneDatepickerI18n,
        },
    ],
})
export class BookingModalLauncherComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() organisationId!: string
    @Input() businessId!: string
    @Input() venueId!: string
    @Input({ transform: booleanAttribute }) noContext = false
    @Input() landingPageId?: string
    @Input({ transform: numberAttribute }) partySize?: number
    @Input({ transform: (value: string) => new Date(value) }) date?: Date
    @Input() idealTime?: string
    @Input({
        transform: (value?: string) => value?.split(','),
    }) restrictedReasonIds?: string[]
    isLoading = false
    venueColour?: string | null
    earliestDate: NgbDateStruct
    availability$!: Observable<Availability>
    form: FormGroup
    partySizePluralMapping = {
        '=1': '1 person',
        other: '# people',
    }
    private ngbDateAdapter = new NgbDateNativeAdapter()
    protected ngbTimeAdapter = new NGBDateTimeAdapter()
    private onDestroy$ = new Subject<void>()

    constructor(
        private availabilityService: AvailabilityService,
        private fb: FormBuilder
    ) {
        this.earliestDate = this.ngbDateAdapter.fromModel(new Date())!
        this.form = this.makeForm()
    }

    ngOnInit() {
        this.reloadAvailability(true)
        this.form.get('date')!.valueChanges
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => {
                this.reloadAvailability(false)
            })
    }

    ngAfterViewInit() {
        this.openBookingModalWithRouteContextIfProvided()
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    dateSelected() {
        this.form.get('showDatePicker')!.setValue(false)
    }

    openBookingModal() {
        window.parent.postMessage({
            type: 'open-booking-modal',
            payload: {
                organisationId: this.organisationId,
                businessId: this.businessId,
                venueId: this.venueId,
                partySize: null,
                date: null,
                idealTime: null,
                restrictedReasonIds: this.restrictedReasonIds,
            },
        },
        '*')
    }

    openBookingModalWithContext() {
        if (this.form.invalid) {
            return
        }
        if (this.landingPageId) {
            this.openLandingPageInNewTab()
            return
        }
        window.parent.postMessage({
            type: 'open-booking-modal',
            payload: {
                organisationId: this.organisationId,
                businessId: this.businessId,
                venueId: this.venueId,
                partySize: this.form.get('partySize')!.value,
                date: this.ngbDateAdapter.toModel(this.form.get('date')!.value),
                idealTime: new Date(this.form.get('idealTime')!.value),
                restrictedReasonIds: this.restrictedReasonIds,
            },
        },
        '*')
    }

    private makeForm() {
        const today = new Date()
        const dateToday = this.ngbDateAdapter.fromModel(today)
        return this.fb.group({
            partySize: [null, [Validators.required, Validators.min(1)]],
            showDatePicker: [false],
            date: [dateToday, [Validators.required]],
            idealTime: [today.getTime(), [Validators.required]],
        })
    }

    private reloadAvailability(initialLoad: boolean) {
        this.availability$ = this.getAvailability()
        this.availability$
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe(availability => {
                this.setPartySizeToDefaultReservablePartySize(availability)
                this.setDefaultIdealTimeToEarliestBookingSlotHourOrThisHour(availability)
                if (initialLoad) {
                    this.adjustDateIfNoBookingSlotsToday(availability)
                }
            })
    }

    private getAvailability() {
        const dateTime = this.ngbDateAdapter.toModel(this.form.get('date')!.value)
        if (!dateTime) {
            throw new Error('Date is null')
        }
        this.isLoading = true
        return this.availabilityService.getAvailability(
            this.organisationId,
            this.businessId,
            this.venueId,
            dateTime,
            dateTime,
            undefined,
            undefined,
            this.restrictedReasonIds
        )
            .pipe(
                finalize(() => this.isLoading = false),
                tap(availability => {
                    this.venueColour = availability.venue.primaryColour
                })
            )
    }

    private setPartySizeToDefaultReservablePartySize(availability: Availability) {
        if (this.form.get('partySize')?.value !== null) {
            return
        }
        this.form.get('partySize')?.setValue(availability.defaultReservablePartySize())
    }

    private setDefaultIdealTimeToEarliestBookingSlotHourOrThisHour(availability: Availability) {
        const earliestBookingSlot = availability.earliestAvailability()?.bookingSlot ?? null
        const time = earliestBookingSlot !== null ? new Date(earliestBookingSlot.dateTime) : new Date()
        time.setMinutes(0, 0, 0)
        this.form.get('idealTime')?.setValue(time.getTime())
    }

    private adjustDateIfNoBookingSlotsToday(availability: Availability) {
        const earliestBookingSlot = availability.earliestAvailability()
        const bookingSlotHasOptions = earliestBookingSlot && earliestBookingSlot.options.length > 0
        if (bookingSlotHasOptions) {
            return
        }
        const date = this.ngbDateAdapter.toModel(this.form.get('date')!.value)
        const today = new Date()
        const isDateToday = date && Helper.startOfDay(date).getTime() === Helper.startOfDay(today).getTime()
        if (!isDateToday) {
            return
        }
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        this.form.get('date')!.setValue(this.ngbDateAdapter.fromModel(tomorrow))
    }

    private openLandingPageInNewTab() {
        const path = '/' + this.landingPageId
        const url = new URL(path, environment.landingPageBaseUrl)
        const partySize = this.form.get('partySize')!.value
        const date = this.ngbDateAdapter.toModel(this.form.get('date')!.value)
        const idealDateTime = new Date(this.form.get('idealTime')!.value)
        const idealTime = new Time(null, idealDateTime.getHours(), idealDateTime.getMinutes())
        if (!partySize || !date || !idealTime) {
            return
        }
        url.searchParams.append('partySize', partySize)
        url.searchParams.append('date', Helper.makeLocalISOFormattedDateString(date))
        url.searchParams.append('idealTime', idealTime.toString())
        window.open(url.toString(), '_blank')
    }

    private openBookingModalWithRouteContextIfProvided() {
        if (!this.partySize || !this.date || !this.idealTime) {
            return
        }
        this.form.get('partySize')!.setValue(this.partySize)
        this.form.get('date')!.setValue(this.ngbDateAdapter.fromModel(this.date))
        const idealTime = new Time(this.idealTime, null, null)
        this.form.get('idealTime')!.setValue(idealTime.dateTimeOnDate(this.date))
        this.openBookingModalWithContext()
    }
}
