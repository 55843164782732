<app-loading
    [observable]="viewModel"
>
    <div class="col offset-md-2 col-md-8 offset-lg-3 col-lg-6">
        <div class="border rounded">
            <app-booking-details
                [viewModel]="viewModel"
            >
            </app-booking-details>
        </div>
    </div>
</app-loading>
