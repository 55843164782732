import { Buffer } from 'buffer'
import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class BookingFeedbackService {

    constructor() {
    }

    shouldRedirect(rating: number | null, reviewCount: number | null) {
        return rating !== null && reviewCount !== null && rating > reviewCount
    }

    makeReviewUrlFromQueryParam(url: string | null | undefined) {
        if (!url) {
            return null
        }
        return Buffer.from(url, 'base64')
            .toString('ascii')
    }
}
