import { Availability } from '../domain/Availability'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'areaName',
})
export class AreaNamePipe implements PipeTransform {

    transform(areaId: string, availability: Availability): string | null {
        const area = availability.areas.find(area => {
            return area.area.id === areaId
        })
        return area ? area.area.displayName : null
    }
}
