<app-spinner-overlay
    [showSpinner]="isLoading"
>
    <ng-container
        *ngIf="noContext"
    >
        <ng-container
            *ngTemplateOutlet="button"
        >
        </ng-container>
    </ng-container>
    <ng-container
        *ngIf="!noContext"
    >
        <ng-container
            *ngTemplateOutlet="contextForm"
        >
        </ng-container>
    </ng-container>
</app-spinner-overlay>

<ng-template #button>
    <button
        appVenueColour
        [primaryColour]="venueColour"
        class="btn btn-lg btn-primary position-absolute top-0 start-0 end-0 bottom-0 w-100 h-100"
        (click)="openBookingModal()"
    >
        <div class="justify-content-center">
            Book Now
        </div>
    </button>
</ng-template>

<ng-template #contextForm>
    <form
        [formGroup]="form"
        *ngIf="availability$ | async as availability"
        appVenueColour
        [primaryColour]="venueColour"
        class="vstack gap-5"
    >
        <ng-container
            *ngIf="form.get('showDatePicker')?.value"
        >
            <div class="vstack bg-white rounded border border-1">
                <ngb-datepicker
                    *ngIf="form.get('showDatePicker')?.value"
                    class="inline compact"
                    formControlName="date"
                    [minDate]="earliestDate"
                    (dateSelect)="dateSelected()"
                >
                </ngb-datepicker>
                <div class="px-2 pb-2">
                    <button
                        class="btn btn-outline-dark hstack justify-content-center gap-2"
                        (click)="form.get('showDatePicker')?.setValue(false)"
                    >
                        <img
                            ngSrc="assets/left/back_dark.svg"
                            width="24"
                            height="24"
                            alt="Back"
                        />
                        Back to Booking
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-container
            *ngIf="!form.get('showDatePicker')?.value"
        >
            <div
                class="vstack gap-2"
            >
                <div class="vstack align-items-stretch position-relative">
                    <label
                        class="form-label mb-1"
                    >
                        Party Size
                    </label>
                    <div class="position-relative">
                        <div class="btn-group dropdown w-100">
                            <button
                                class="btn btn-outline-secondary dropdown-toggle hstack gap-2"
                                (click)="partySizeSelect.click()"
                            >
                                <img
                                    ngSrc="assets/people/normal.svg"
                                    width="20"
                                    height="20"
                                    alt="party size"
                                >
                                <span
                                    *ngIf="form.get('partySize')?.value === availability.venue.minLargePartySize"
                                >
                                    {{ availability.venue.minLargePartySize | i18nPlural: partySizePluralMapping }} +
                                </span>
                                <span
                                    *ngIf="form.get('partySize')?.value !== availability.venue.minLargePartySize"
                                >
                                    {{ form.get('partySize')?.value | i18nPlural: partySizePluralMapping }}
                                </span>
                            </button>
                        </div>
                        <select
                            #partySizeSelect
                            class="form-select btn opacity-0 position-absolute top-0 start-0 w-100 h-100"
                            formControlName="partySize"
                        >
                            <option
                                *ngFor="let size of availability.possiblePartySizes"
                                [ngValue]="size"
                            >
                                {{ size | i18nPlural: partySizePluralMapping }}
                            </option>
                            <option
                                *ngIf="availability.venue.minLargePartySize !== null"
                                class="dropdown-item"
                                [ngValue]="availability.venue.minLargePartySize"
                            >
                                {{ availability.venue.minLargePartySize }}+
                            </option>
                        </select>
                    </div>
                </div>
                <ng-container
                    *ngIf="availability.venue.minLargePartySize === null || form.get('partySize')?.value < availability.venue.minLargePartySize"
                >
                    <div class="vstack">
                        <label class="form-label mb-1">
                            Date
                        </label>
                        <button
                            class="btn btn-outline-secondary dropdown-toggle flex-grow-1"
                            (click)="form.get('showDatePicker')?.setValue(!form.get('showDatePicker')?.value)"
                        >
                            <div class="calendar"></div>
                            {{ form.get('date')?.value | ngbDate | relativeDate: 'mediumDate' }}
                        </button>
                    </div>
                    <div class="vstack align-items-stretch position-relative">
                        <label
                            for="idealTime"
                            class="form-label mb-1"
                        >
                            Ideal Time
                        </label>
                        <div class="position-relative btn-group dropdown">
                            <button
                                class="btn btn-outline-secondary dropdown-toggle"
                                (click)="idealTimeSelect.click()"
                            >
                                <div class="clock"></div>
                                <span>
                                    {{ form.get('idealTime')?.value | date: 'shortTime' }}
                                </span>
                            </button>
                        </div>
                        <select
                            #idealTimeSelect
                            class="form-select btn opacity-0 position-absolute top-0 start-0 w-100 h-100"
                            id="idealTime"
                            formControlName="idealTime"
                        >
                            <option
                                *ngFor="let time of availability.intervalIdealTimes"
                                [ngValue]="time.getTime()"
                            >
                                {{ time | date: 'shortTime' }}
                            </option>
                        </select>
                    </div>
                </ng-container>
            </div>
            <button
                *ngIf="availability.venue.minLargePartySize === null || form.get('partySize')?.value < availability.venue.minLargePartySize"
                class="btn btn-primary hstack justify-content-center gap-2"
                (click)="openBookingModalWithContext()"
            >
                Check Availability
                <img
                    *ngIf="landingPageId !== undefined"
                    ngSrc="assets/right/forward_white_semibold.svg"
                    width="24"
                    height="24"
                    alt="Check Availability"
                >
            </button>
            <ng-container
                *ngIf="availability.venue.minLargePartySize !== null && form.get('partySize')?.value >= availability.venue.minLargePartySize"
            >
                <ng-container
                    *ngTemplateOutlet="largePartySizeMessage; context: { availability: availability }"
                >
                </ng-container>
            </ng-container>
        </ng-container>
    </form>
</ng-template>

<ng-template
    #largePartySizeMessage
    let-availability="availability"
>
    <div class="text-center fw-semibold p-3">
        <ng-container
            *ngIf="availability.venue.largePartyMessage"
        >
            <span [innerHTML]="availability.venue.largePartyMessage"></span>
        </ng-container>
        <ng-container
            *ngIf="!availability.venue.largePartyMessage"
        >
            To ensure the best possible experience, we ask that you get in touch at
            <a [href]="availability.venue.phoneNumber"> {{ availability.venue.phoneNumber }}</a>
            <ng-container
                *ngIf="availability.venue.emailAddress"
            >
                or
                <a [href]="'mailto:' + availability.venue.emailAddress"> {{ availability.venue.emailAddress }}</a>
            </ng-container>
            to make your booking.
            <br/>
            We look forward to seeing you soon.
        </ng-container>
    </div>
</ng-template>
