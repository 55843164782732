<div class="py-1 py-md-3">
    <div class="container">
        <div
            class="px-1 px-md-3 py-3 text-center text-md-start vstack gap-4"
            *ngIf="observable | withLoading | async as observable"
        >
            <ng-template [ngIf]="observable.value">
                <ng-content></ng-content>
            </ng-template>
            <ng-template [ngIf]="observable.loading">
                <div class="d-flex align-content-center justify-content-center">
                    <div class="spinner-border text-secondary" role="status">
                    </div>
                </div>
            </ng-template>
            <ng-template [ngIf]="observable.error">
                <app-error></app-error>
            </ng-template>
        </div>
    </div>
</div>
