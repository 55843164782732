import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'ngbTime',
})
export class NgbTimePipe implements PipeTransform {

    transform(ngbTime: NgbTimeStruct | null): Date | null {
        if (!ngbTime) {
            return null
        }
        const date = new Date()
        date.setHours(ngbTime.hour)
        date.setMinutes(ngbTime.minute)
        date.setSeconds(ngbTime.second)
        return date
    }
}
