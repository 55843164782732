import { Component } from '@angular/core'

@Component({
    selector: 'app-booking-feedback-confirmation',
    templateUrl: './booking-feedback-confirmation.component.html',
    styleUrls: ['./booking-feedback-confirmation.component.sass'],
})
export class BookingFeedbackConfirmationComponent {

}
