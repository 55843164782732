import { BookingDetails } from '../domain/BookingDetails'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-confirm-cancellation',
    templateUrl: './confirm-cancellation.component.html',
})
export class ConfirmCancellationComponent implements OnInit {

    @Input() bookingDetails!: BookingDetails
    @Output() cancelSelected = new EventEmitter<void>()
    @Output() confirmSelected = new EventEmitter<void>()

    constructor() { }

    ngOnInit() { }
}
