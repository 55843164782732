import { Area } from '../domain/Area'
import { Availability } from '../domain/Availability'
import { BookingReason } from '../domain/BookingReason'
import { BookingSlotAvailability } from '../domain/BookingSlotAvailability'
import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { ContextService } from '../services/ContextService'
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms'
import { Helper } from '../utilities/helper'
import { RouterContextService } from '../services/RouterContextService'
import { combineLatest, filter } from 'rxjs'
import { startWith } from 'rxjs/operators'

@Component({
    selector: 'app-booking-slot',
    templateUrl: './booking-slot.component.html',
    styleUrls: ['./booking-slot.component.sass'],
    providers: [
        {
            provide: ContextService,
            useClass: RouterContextService,
        },
    ],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
        },
    ],
})
export class BookingSlotComponent implements OnInit, OnChanges {

    @Input() availability!: Availability
    form: FormGroup
    possiblePartySizes!: number[]
    minLargePartySize!: number | null
    possibleTimes!: Date[]
    reasons: BookingReason[] = []
    areas: Area[] = []
    possibleBookingSlots: { bookingSlot: BookingSlotAvailability, isReservable: boolean }[] = []
    venueIsClosed = false
    needsToSelectReason = false
    needsToSelectArea = false
    allPossibleBookingSlotsUnavailable = false
    hasSelectedALargePartySize = false
    today = Helper.makeNgbDate(new Date())

    constructor(
        private rootFormGroup: FormGroupDirective
    ) {
        this.form = this.rootFormGroup.form.get('BookingSlot') as FormGroup
    }

    ngOnInit() {
        this.bindParametersToBookingSlots()
        this.bindFormToReasonRequired()
    }

    ngOnChanges() {
        this.possiblePartySizes = this.availability.possiblePartySizes
        this.minLargePartySize = this.availability.venue.minLargePartySize
        this.possibleTimes = this.makeIntervalBasedIdealTimes()
        this.venueIsClosed = this.availability.areas.every(area => !area.areaCanBeBooked)
        this.setReasonsToOnesUsedOnSelectedDate()
        this.areas = this.availability.bookableAreas().flatMap(area => area.area)
        this.adjustSelectedTimeIfNotAvailable()
        this.setPartySizeToDefaultReservablePartySize()
        this.setDefaultTimeToEarliestBookingSlotHourOrThisHour()
        this.setReasonRequiredIfReasonsExistAndNoReasonSelected()
        this.setAreaRequiredIfReasonSelectedAndNoAreaSelected()
        this.setDefaultAreaToFirstAreaIfReasonNotRequiredFirst()
        this.changeSelectedAreaIfNoLongerAvailable()
        this.removeSelectedReasonIfNoLongerAvailable()
    }

    partySizeSelected(partySize: number) {
        this.form.get('partySize')?.setValue(partySize)
    }

    timeSelected(time: Date) {
        this.form.get('selectedTime')?.setValue(time)
    }

    get selectedReason() {
        const selectedReasonId = this.form.get('selectedReasonId')?.value
        if (selectedReasonId === null) {
            return null
        }
        return this.availability.venue.reasonWithId(selectedReasonId)
    }

    private makeIntervalBasedIdealTimes(): Date[] {
        const start = this.availability.earliestBookingSlot()?.dateTime
        if (!start) {
            return []
        }
        const end = this.availability.latestBookingSlot()?.dateTime
        if (!end) {
            return []
        }
        const times: Date[] = []
        let startTime = new Date(start)
        startTime.setMinutes(0, 0, 0)
        for (let time = startTime; time <= end; time.setMinutes(time.getMinutes() + 15)) {
            times.push(new Date(time))
        }
        return times
    }

    private setReasonsToOnesUsedOnSelectedDate() {
        const ngbDay = this.form.get('selectedDate')?.value
        if (ngbDay === null) {
            return
        }
        const date = Helper.makeDate(ngbDay)
        this.reasons = this.availability.venue.reasonsUsedOnDate(date)
    }

    private setPartySizeToDefaultReservablePartySize() {
        if (this.form.get('partySize')?.value !== null) {
            return
        }
        this.form.get('partySize')?.setValue(this.availability.defaultReservablePartySize())
    }

    private setDefaultTimeToEarliestBookingSlotHourOrThisHour() {
        if (this.form.get('selectedTime')?.value !== null) {
            return
        }
        const earliestBookingSlot = this.availability.earliestAvailability()?.bookingSlot ?? null
        const time = earliestBookingSlot !== null ? new Date(earliestBookingSlot.dateTime) : new Date()
        time.setMinutes(0, 0, 0)
        this.form.get('selectedTime')?.setValue(time)
    }

    private adjustSelectedTimeIfNotAvailable() {
        const selectedTime = this.form.get('selectedTime')!.value
        if (selectedTime === null) {
            return
        }
        const selectedTimeOfDay = selectedTime.getHours() * 60 + selectedTime.getMinutes()
        const possibleTimesOfDay = this.possibleTimes.map(time => time.getHours() * 60 + time.getMinutes())
        const selectedTimePossibilityIndex = possibleTimesOfDay.indexOf(selectedTimeOfDay)
        if (selectedTimePossibilityIndex !== -1) {
            this.form.get('selectedTime')?.setValue(this.possibleTimes[selectedTimePossibilityIndex])
            return
        }
        if (this.possibleTimes.length === 0) {
            const now = new Date()
            now.setMinutes(0, 0, 0)
            this.form.get('selectedTime')?.setValue(now)
        } else {
            this.form.get('selectedTime')?.setValue(this.possibleTimes[0])
        }
    }

    private bindParametersToBookingSlots() {
        const area = this.form.get('selectedAreaId')!.valueChanges
            .pipe(
                startWith(this.form.get('selectedAreaId')!.value),
                filter(value => value !== null)
            )
        const date = this.form.get('selectedDate')!.valueChanges
            .pipe(
                startWith(this.form.get('selectedDate')!.value),
                filter(value => value !== null)
            )
        const time = this.form.get('selectedTime')!.valueChanges
            .pipe(
                startWith(this.form.get('selectedTime')!.value),
                filter(value => value !== null)
            )
        const partySize = this.form.get('partySize')!.valueChanges
            .pipe(
                startWith(this.form.get('partySize')!.value),
                filter(value => value !== null)
            )
        const reason = this.form.get('selectedReasonId')!.valueChanges
            .pipe(
                startWith(this.form.get('selectedReasonId')!.value)
            )
        combineLatest([
            area,
            date,
            time,
            partySize,
            reason,
        ])
            .subscribe((values) => {
                this.updateBookingSlots(values[0], values[2], values[3], values[4])
            })
    }

    private updateBookingSlots(
        areaId: string,
        selectedTime: Date,
        partySize: number,
        reasonId: string | null
    ) {
        if (this.availability === undefined) {
            return
        }
        this.hasSelectedALargePartySize = partySize === this.availability.venue.minLargePartySize
        const areaAvailability = this.availability.areas.find(a => a.area.id === areaId)
        if (!areaAvailability) {
            return
        }
        const reason = reasonId !== null ? this.availability.venue.reasonWithId(reasonId) : null
        this.possibleBookingSlots = areaAvailability.bookingSlotsAroundTime(selectedTime)
            .map(bookingSlot => {
                return {
                    bookingSlot: bookingSlot,
                    isReservable: bookingSlot.isReservableWithConfiguration(partySize, reason, null),
                }
            })
        this.allPossibleBookingSlotsUnavailable = this.possibleBookingSlots.length > 0 &&
            this.possibleBookingSlots.every(bookingSlot => {
                return !bookingSlot.isReservable
            })
    }

    private bindFormToReasonRequired() {
        this.form.get('selectedReasonId')!.valueChanges
            .subscribe(() => {
                this.setReasonRequiredIfReasonsExistAndNoReasonSelected()
                this.setAreaRequiredIfReasonSelectedAndNoAreaSelected()
            })
    }

    private setReasonRequiredIfReasonsExistAndNoReasonSelected() {
        if (this.reasons.length === 0) {
            this.needsToSelectReason = false
            return
        }
        const allAreasAreClosed = this.availability.areas.every(area => !area.areaCanBeBooked)
        if (allAreasAreClosed) {
            this.needsToSelectReason = false
            return
        }
        const selectedReasonId = this.form.get('selectedReasonId')?.value
        this.needsToSelectReason = selectedReasonId === null
    }

    private setAreaRequiredIfReasonSelectedAndNoAreaSelected() {
        if (this.reasons.length === 0) {
            this.needsToSelectArea = false
            return
        }
        const selectedReasonId = this.form.get('selectedReasonId')?.value
        if (selectedReasonId === null) {
            this.needsToSelectArea = false
            return
        }
        const selectedAreaId = this.form.get('selectedAreaId')?.value
        this.needsToSelectArea = selectedAreaId === null
    }

    private setDefaultAreaToFirstAreaIfReasonNotRequiredFirst() {
        if (this.form.get('selectedAreaId')?.value !== null) {
            return
        }
        if (this.areas.length === 0) {
            return
        }
        if (this.reasons.length > 0) {
            return
        }
        this.form.get('selectedAreaId')?.setValue(this.areas[0].id)
    }

    private changeSelectedAreaIfNoLongerAvailable() {
        const selectedAreaId = this.form.get('selectedAreaId')?.value
        if (selectedAreaId === null) {
            return
        }
        const selectedArea = this.areas.find(area => area.id === selectedAreaId)
        if (selectedArea !== undefined) {
            return
        }
        if (this.areas.length === 0) {
            this.form.get('selectedAreaId')?.setValue(null)
            return
        }
        this.form.get('selectedAreaId')?.setValue(this.areas[0].id)
    }

    private removeSelectedReasonIfNoLongerAvailable() {
        const selectedReasonId = this.form.get('selectedReasonId')?.value
        if (selectedReasonId === null) {
            return
        }
        const selectedReason = this.reasons.find(reason => reason.id === selectedReasonId)
        if (selectedReason !== undefined) {
            return
        }
        this.form.get('selectedReasonId')?.setValue(null)
    }
}
