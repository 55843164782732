import { DTOAdapter } from './DTOs/DTOAdapter'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Venue } from '../domain/Venue'
import { VenueDTO } from './DTOs/VenueDTO'
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class VenueService {

    constructor(
        private http: HttpClient,
        private dtoAdapter: DTOAdapter
    ) { }

    getWithId(venueId: string): Observable<Venue> {
        const path = `/booking/venue/${venueId}`
        const url = new URL(path, environment.apiBaseUrl)
        return this.http.get<VenueDTO>(url.toString())
            .pipe(
                map(dto => this.dtoAdapter.adaptVenueDto(dto))
            )
    }
}
