<div class="vstack gap-3">
    <div class="vstack gap-2">
        <div class="vstack gap-2">
            <h5 class="fw-semibold m-0 px-3">
                Reason for your visit
            </h5>
        </div>
        @if (hasReasonsOutsideIdealTimeRange) {
            <span class="small text-secondary px-3">
                {{ numberOfReasonsOutsideIdealTimeRange | i18nPlural : reasonPluralMapping}}
                available outside {{ form.get('idealTime')?.value | date : 'shortTime' }}
            </span>
            <div
                class="btn-group segmented-control flex-fill-equally"
                role="group"
            >
                <input
                    type="radio"
                    class="btn-check"
                    id="reasons-at-ideal-time"
                    autocomplete="off"
                    [checked]="!showAllReasons"
                >
                <label
                    class="btn btn-outline-primary btn-sm"
                    for="reasons-at-ideal-time"
                    data-test="reasons-at-ideal-time"
                    (click)="toggleShowAllReasons()"
                >
                    Available at {{ form.get('idealTime')?.value | date : 'shortTime' }}
                </label>
                <input
                    type="radio"
                    class="btn-check"
                    id="all-reasons"
                    autocomplete="off"
                    [checked]="showAllReasons"
                >
                <label
                    class="btn btn-outline-primary btn-sm"
                    for="all-reasons"
                    data-test="all-reasons"
                    (click)="toggleShowAllReasons()"
                >
                    Available Any Time
                </label>
            </div>
        }
    </div>
    <div class="vstack gap-2">
        @if (hasReasonsFittingPartySize) {
            @if (showAllReasons) {
                @for (reason of allReasons; track reason.id) {
                    <ng-container
                        *ngTemplateOutlet="reasonLabel; context: { reason: reason }"
                    >
                    </ng-container>
                }
            } @else {
                @for (reason of reasonsAtIdealTime; track reason.id) {
                    <ng-container
                        *ngTemplateOutlet="reasonLabel; context: { reason: reason }"
                    >
                    </ng-container>
                }
            }
        } @else {
            <app-no-availability
                [availability]="availability"
                [areaId]="form.get('selectedAreaId')?.value"
            >
            </app-no-availability>
        }
    </div>
</div>

<ng-template
    #reasonLabel
    let-reason="reason"
>
    <label
        class="btn btn-outline-secondary p-2 d-flex flex-column justify-content-center align-items-center"
        data-test="reason-select"
    >
        <div class="m-1 hstack gap-2 text-start">
            <input
                type="radio"
                class="form-check-input m-0"
                role="button"
                [value]="reason.id"
                [checked]="reason.id === form.get('selectedReasonId')?.value"
                (change)="reasonSelected.emit(reason)"
            >
            <div class="vstack align-items-start">
                <div class="hstack gap-2 justify-content-between me-1">
                    <span class="fw-semibold">
                        {{ reason.displayName }}
                    </span>
                    <span class="small text-secondary">
                        {{ reason | reasonTime : form.get('idealTime')?.value }}
                    </span>
                </div>
                <span
                    *ngIf="reason.description"
                    class="small"
                >
                    {{ reason.description }}
                </span>
            </div>
        </div>
    </label>
</ng-template>
