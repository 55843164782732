import { Availability } from '../../domain/Availability'
import { BookingReason } from '../../domain/BookingReason'
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
} from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
    selector: 'app-reason-selection',
    templateUrl: './reason-selection.component.html',
})
export class ReasonSelectionComponent implements OnChanges {

    @Input() availability!: Availability
    @Input() form!: FormGroup
    @Input() noAvailability!: TemplateRef<any>
    @Output() reasonSelected = new EventEmitter<BookingReason>()
    hasReasonsFittingPartySize = false
    showAllReasons = false
    allReasons: BookingReason[] = []
    reasonsAtIdealTime: BookingReason[] = []
    hasReasonsOutsideIdealTimeRange = false
    numberOfReasonsOutsideIdealTimeRange = 0
    reasonPluralMapping = {
        '=1': 'There is one reason',
        other: 'There are # reasons',
    }

    ngOnChanges(changes: SimpleChanges) {
        this.hasReasonsFittingPartySize = this.availability.doesConfigurationHaveAnyReservableReasons(
            this.form.get('partySize')?.value,
            this.form.get('idealTime')?.value
        )
        const idealTime = this.form.get('idealTime')?.value
        this.allReasons = this.availability.venue.reasonsUsedOnDate(idealTime)
        this.reasonsAtIdealTime = this.availability.venue.reasonsUsedOnDateTime(idealTime)
        const hasRestrictedReasons = this.allReasons.length !== this.reasonsAtIdealTime.length
        this.hasReasonsOutsideIdealTimeRange = hasRestrictedReasons
        this.numberOfReasonsOutsideIdealTimeRange = this.allReasons.length - this.reasonsAtIdealTime.length
    }

    toggleShowAllReasons() {
        this.showAllReasons = !this.showAllReasons
    }

    private refreshReasons() {
        const idealTime = this.form.get('idealTime')?.value
        this.allReasons = this.availability.venue.reasonsUsedOnDate(idealTime)
        this.reasonsAtIdealTime = this.availability.venue.reasonsUsedOnDateTime(idealTime)
        const hasRestrictedReasons = this.allReasons.length !== this.reasonsAtIdealTime.length
        this.hasReasonsOutsideIdealTimeRange = hasRestrictedReasons
    }
}
