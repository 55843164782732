import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class WindowService {

    getLocation(): Location {
        return window.location
    }

    navigateToUrl(url: string) {
        window.location.href = url
    }

    openWindowAtUrl(url: string) {
        window.open(url, '_blank')
    }
}
