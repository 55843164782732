import { BookingDetails } from '../domain/BookingDetails'
import { BookingDetailsViewModel } from '../booking-summary/BookingDetailsViewModel'
import { Component, Input, OnInit, Output } from '@angular/core'
import { Observable, Subject, of } from 'rxjs'

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
})
export class ConfirmationComponent implements OnInit {

    @Input() details!: BookingDetails
    @Output() bookAgainSelected = new Subject<void>()
    viewModel!: Observable<BookingDetailsViewModel>

    constructor() { }

    ngOnInit() {
        this.viewModel = of(
            new BookingDetailsViewModel(this.details, true, false)
        )
    }
}
