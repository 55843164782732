import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core'
import { adjustLightness } from '../utilities/rgb-to.hsl'

@Directive({
    selector: '[appVenueColour]',
})
export class VenueColourDirective implements OnInit, OnChanges {

    @Input() primaryColour?: string | null

    constructor(
      private elementRef: ElementRef
    ) { }

    ngOnInit() {
        this.updateColourVariable()
    }

    ngOnChanges() {
        this.updateColourVariable()
    }

    private updateColourVariable() {
        let primaryColour = this.primaryColour ?? '21, 136, 73'
        let lightColour = adjustLightness(primaryColour, 0.2)
        let darkColour = adjustLightness(primaryColour, -0.2)
        this.elementRef.nativeElement.style.setProperty(
            '--app-primary-colour',
            this.addRGBSelector(primaryColour)
        )
        this.elementRef.nativeElement.style.setProperty(
            '--app-primary-colour-light',
            this.addRGBSelector(lightColour)
        )
        this.elementRef.nativeElement.style.setProperty(
            '--app-primary-colour-dark',
            this.addRGBSelector(darkColour)
        )
    }

    private addRGBSelector(rgb: string): string {
        return `rgb(${rgb})`
    }
}
