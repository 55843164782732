<div [formGroup]="form" class="d-flex flex-column flex-sm-row gap-2 gap-sm-3">
    <select
        id="day"
        class="form-select w-auto"
        [class.fw-semibold]="form.get('day')?.value"
        formControlName="day"
        (blur)="onBlur()"
    >
        <option value="null">Select Day</option>
        <option *ngFor="let day of days" [value]="day">{{ day }}</option>
    </select>
    <select
        id="month"
        class="form-select w-auto"
        [class.fw-semibold]="form.get('month')?.value"
        formControlName="month"
        (blur)="onBlur()"
    >
        <option value="null">Select Month</option>
        <option *ngFor="let month of months; index as i" [value]="(i + 1).toString().padStart(2, '0')">
            {{ month }}
        </option>
    </select>
</div>
