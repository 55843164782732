import { ActivatedRoute } from '@angular/router'
import { BookingFeedback } from '../domain/BookingFeedback'
import { BookingFeedbackService } from '../services/booking-feedback.service'
import { BookingService } from '../services/booking.service'
import { Component } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Helper } from '../utilities/helper'
import { finalize } from 'rxjs'
import { v4 as uuidv4 } from 'uuid'

export enum FeedbackFormStep {
    FeedbackForm = 'FeedbackForm',
    Confirmation = 'FeedbackConfirmation',
}

@Component({
    selector: 'app-booking-feedback',
    templateUrl: './booking-feedback.component.html',
})
export class BookingFeedbackComponent {
    bookingId: string
    rating: number | null
    reviewCount: number | null
    isLoading = true
    isSubmitting = false
    feedbackRequestFormError: string | null = null
    form: FormGroup
    step: FeedbackFormStep
    formStep = FeedbackFormStep

    constructor(
        private fb: FormBuilder,
        private bookingService: BookingService,
        private bookingFeedbackService: BookingFeedbackService,
        route: ActivatedRoute
    ) {
        this.step = FeedbackFormStep.FeedbackForm
        this.bookingId = route.snapshot.params['bookingId']
        this.rating = Helper.makeNumberOrNull(route.snapshot.queryParams['rating'])
        this.reviewCount = Helper.makeNumberOrNull(route.snapshot.queryParams['count'])
        this.redirectToExternalReviewUrl(route.snapshot.queryParams['redirect_url'])
        this.form = this.makeForm()
    }

    private makeForm() {
        return this.fb.group({
            'FeedbackForm': this.fb.group({
                rating: new FormControl(this.rating, Validators.min(1)),
                comment: new FormControl(null),
            }),
        })
    }

    onSubmitFeedbackSelected() {
        this.isSubmitting = true
        this.submitFeedback()
    }

    private submitFeedback() {
        let feedbackForm = this.form.get(FeedbackFormStep.FeedbackForm) as FormGroup
        let feedback = new BookingFeedback(
            uuidv4(),
            feedbackForm.get('rating')!.value,
            feedbackForm.get('comment')!.value,
            false,
            new Date()
        )
        this.bookingService.submitFeedback(this.bookingId,feedback)
            .pipe(
                finalize(() => this.isSubmitting = false)
            )
            .subscribe({
                next: _ => {
                    this.step = FeedbackFormStep.Confirmation
                },
                error: _ => {
                    this.feedbackRequestFormError =
                        'There was an error submitting your feedback. ' +
                        'Please refresh the page and try again.'
                },
            })
    }

    private redirectToExternalReviewUrl(reviewsUrl: string | null = null) {
        let reviewUrl = this.bookingFeedbackService.makeReviewUrlFromQueryParam(reviewsUrl)
        if (this.bookingFeedbackService.shouldRedirect(this.rating, this.reviewCount)
            && reviewUrl !== null && this.rating !== null ) {
            let feedback = new BookingFeedback(
                uuidv4(),
                this.rating,
                null,
                true,
                new Date()
            )
            this.bookingService.submitFeedback(this.bookingId,feedback)
                //listen to the response and redirect to the reviewUrl
                .subscribe({
                    next: _ => {
                        window.location.href = reviewUrl
                    },
                    error: _ => {
                        window.location.href = reviewUrl
                    },
                })
            return
        }
        this.isLoading = false
    }

}
