import { Helper } from '../utilities/helper'
import { NgbDate } from '@ng-bootstrap/ng-bootstrap'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'ngbDate',
})
export class NgbDatePipe implements PipeTransform {

    transform(ngbDate: NgbDate | null): Date | null {
        if (!ngbDate) {
            return null
        }
        return Helper.makeDate(ngbDate)
    }
}
