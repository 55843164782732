<div class="modal-header">
    <h4 class="modal-title fw-semibold fs-5">
        Cancel Booking
    </h4>
</div>
<div class="modal-body">
    <div class="vstack gap-2">
        <span class="text-muted">
            Are you sure you want to cancel your booking?
            If you change your mind, you will have to book again.
        </span>
        @if (bookingDetails.cancellationCharge !== null) {
            <p>
                If you cancel this booking after
                <span class="fw-semibold">
                    {{ bookingDetails.cancellationChargeCutOffTime | date:'shortTime' }}
                </span>
                on
                <span class="fw-semibold">
                    {{ bookingDetails.cancellationChargeCutOffTime | date:'fullDate' }}</span>,
                    you will be charged a cancellation fee of
                <span class="fw-semibold">
                    {{ bookingDetails.cancellationCharge | currencyUnit }}</span>.
            </p>
        }
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-outline-secondary"
        autofocus
        (click)="cancelSelected.emit()"
    >
        Cancel
    </button>
    <button
        type="button"
        class="btn btn-danger"
        (click)="confirmSelected.emit()"
    >
        Confirm Cancellation
    </button>
</div>
