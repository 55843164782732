import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'

@Injectable({
    providedIn: 'root',
})
export class GetDirectionsService {

    private getDirectionsURL = environment.getDirectionsURL

    constructor() {}

    createURL(url: string): string {
        const returnDirectionsURL = this.getDirectionsURL + encodeURIComponent(url)

        return returnDirectionsURL
    }
}
