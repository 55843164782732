<app-spinner-overlay
    [showSpinner]="isSaving"
>
    <ng-container
        *ngIf="paymentDetailsFormError"
    >
        <app-error
            [error]="paymentDetailsFormError"
        >
        </app-error>
    </ng-container>
    <form
        appVenueColour
        [primaryColour]="venue?.primaryColour"
        [formGroup]="form"
        class="h-100 vstack gap-3"
    >
        <div class="vstack gap-2">
            <h5
                *ngIf="showTitle"
            >
                Card Details
            </h5>
            <div class="vstack gap-2">
                <label class="text-muted">
                    We require a card to secure your booking.
                    Cancellations or no-shows
                    <ng-container
                        *ngIf="cancellationChargeCutOffHours !== null"
                    >
                        with less than
                        {{ cancellationChargeCutOffHours | i18nPlural : hoursPluralMapping }}
                        notice
                    </ng-container>
                    may be charged
                    <span class="fw-bold">
                        {{ cancellationChargeAmount.toFormat() }}
                        <ng-container
                            *ngIf="cancellationChargeIsPerCover"
                        >
                            per person</ng-container></span>.
                </label>
                <div
                    id="payment-intent"
                >
                </div>
            </div>
        </div>
        <div class="vstack justify-content-end">
            <div class="hstack gap-2">
                <button
                    *ngIf="showBackButton"
                    type="button"
                    class="btn btn-secondary w-auto"
                    (click)="onBackSelected()"
                >
                    Back
                </button>
                <div
                    *ngIf="showBackButton"
                    class="flex-grow-1 ms-auto me-auto"
                >
                </div>
                <button
                    type="button"
                    class="btn btn-primary"
                    [ngClass]="showBackButton ? 'w-auto' : 'flex-grow-1'"
                    [disabled]="!form.valid || !cardDetailsAreComplete"
                    (click)="onBookSelected()"
                >
                    Book
                </button>
            </div>
        </div>
    </form>
</app-spinner-overlay>
