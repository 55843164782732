import { Pipe, PipeTransform } from '@angular/core'
import { Venue } from '../domain/Venue'

@Pipe({
    name: 'eventName',
})
export class EventNamePipe implements PipeTransform {

    transform(eventId: string, venue: Venue): string | null {
        const event = venue.events.find(event => {
            return event.id === eventId
        })
        return event ? event.displayName : null
    }
}
