import { Appearance, Stripe, StripeElements, loadStripe } from '@stripe/stripe-js'
import { BookingDetails } from '../domain/BookingDetails'
import { BookingRequest } from '../domain/BookingRequest'
import { DTOAdapter } from './DTOs/DTOAdapter'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PaymentIntent } from '../domain/PaymentIntent'
import { environment } from '../../environments/environment'
import { firstValueFrom } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class PaymentsService {

    private stripe: Promise<Stripe> | null = null

    constructor(
        private http: HttpClient,
        private dtoAdapter: DTOAdapter
    ) { }

    setUpForAccount(paymentsAccountId: string): void {
        if (this.stripe !== null) {
            return
        }
        this.stripe = new Promise((resolve) => {
            resolve(
                loadStripe(environment.stripePublishableKey, {
                    stripeAccount: paymentsAccountId,
                })
                    .then((stripe) => {
                        if (stripe === null) {
                            throw new Error('Stripe not loaded')
                        }
                        return stripe
                    })
            )
        })
    }

    async makeSetupIntentClientSecret(
        organisationId: string,
        businessId: string,
        bookingDate: Date
    ): Promise<PaymentIntent> {
        let params = new HttpParams()
            .set('bookingDate', this.makeLocalISOFormattedDateString(bookingDate))
        const url = `
${environment.apiBaseUrl}\
/booking\
/organisation/${organisationId}\
/business/${businessId}\
/payments\
/setup-details\
?${params.toString()}`
        const payment = this.http.post<PaymentIntent>(
            url,
            { bookingDate }
        )
        return firstValueFrom(payment)
    }

    async makePaymentIntentClientSecret(
        organisationId: string,
        businessId: string,
        venueId: string,
        areaId: string,
        bookingRequest: BookingRequest
    ): Promise<PaymentIntent> {
        let params = new HttpParams()
            .set('venueId', venueId)
            .set('areaId', areaId)
        const url = `
${environment.apiBaseUrl}\
/booking\
/organisation/${organisationId}\
/business/${businessId}\
/payments\
/payment-intent\
?${params.toString()}`
        const body = this.dtoAdapter.adaptBookingRequest(bookingRequest)
        const payment = this.http.post<PaymentIntent>(
            url,
            body
        )
        return firstValueFrom(payment)
    }

    async makePendingBookingPaymentIntentClientSecret(
        organisationId: string,
        businessId: string,
        bookingDetails: BookingDetails
    ): Promise<PaymentIntent> {
        let params = new HttpParams()
            .set('bookingId', bookingDetails.bookingId)
        const url = `
${environment.apiBaseUrl}\
/booking\
/organisation/${organisationId}\
/business/${businessId}\
/payments\
/payment-intent\
?${params.toString()}`
        const payment = this.http.post<PaymentIntent>(url, null)
        return firstValueFrom(payment)
    }

    async createStripeElements(
        clientSecret?: string,
        amount?: number,
        currencyCode?: string
    ): Promise<StripeElements> {
        if (this.stripe === null) {
            throw new Error('Stripe must be set up first')
        }
        const stripe = await this.stripe
        const appearance: Appearance = {
            theme: 'stripe',
            variables: {
                fontFamily: 'Source Sans Pro',
                fontSizeSm: '1rem',
                colorPrimary: '#158848',
                colorWarning: '#DA3A3E',
                colorWarningText: '#DA3A3E',
            },
        }
        return stripe.elements({
            appearance: appearance,
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro',

                },
            ],
            clientSecret: clientSecret,
        })
    }

    async savePaymentDetails(elements: StripeElements): Promise<string> {
        if (this.stripe === null) {
            throw new Error(undefined)
        }
        const stripe = await this.stripe
        const setupResult = await stripe.confirmSetup({
            elements: elements,
            redirect: 'if_required',
        })
        if (setupResult.error) {
            throw new Error(setupResult.error.message)
        }
        const intent = setupResult.setupIntent
        if (!intent) {
            throw new Error(undefined)
        }
        // TODO: Handle if payment details need confirmation or other action
        return intent.payment_method as string
    }

    async confirmPayment(elements: StripeElements): Promise<string> {
        if (this.stripe === null) {
            throw new Error(undefined)
        }
        const stripe = await this.stripe
        const paymentResult = await stripe.confirmPayment({
            elements: elements,
            redirect: 'if_required',
        })
        if (paymentResult.error) {
            throw new Error(paymentResult.error.message)
        }
        const intent = paymentResult.paymentIntent
        if (!intent) {
            throw new Error(undefined)
        }
        return intent.id as string
    }

    private makeLocalISOFormattedDateString(date: Date): string {
        let offsetMs = date.getTimezoneOffset() * 60 * 1000
        let msLocal =  date.getTime() - offsetMs
        let dateLocal = new Date(msLocal)
        let iso = dateLocal.toISOString()
        let [isoLocal] = iso.split('T')
        return isoLocal
    }
}
