<app-spinner-overlay
    [showSpinner]="isLoading"
>
</app-spinner-overlay>
<ng-container *ngIf="!isLoading">
    <div
        [formGroup]="form"
        [ngSwitch]="step"
        appVenueColour
    >
        <app-spinner-overlay
            [showSpinner]="isLoading"
        >
        </app-spinner-overlay>
        <div class="container col-sm-10 col-md-8 col-lg-6 col-xl-4 my-2">
            <ng-container
                *ngIf="feedbackRequestFormError"
            >
                <app-error
                    [error]="feedbackRequestFormError"
                >
                </app-error>
            </ng-container>
        </div>
        <ng-container
            *ngIf="!feedbackRequestFormError"
        >
            <app-booking-feedback-form
                *ngSwitchCase="formStep.FeedbackForm"
                (submitFeedbackSelected)="onSubmitFeedbackSelected()"
            >
            </app-booking-feedback-form>
        </ng-container>
        <ng-container
            *ngSwitchCase="formStep.Confirmation"
        >
            <app-booking-feedback-confirmation>
            </app-booking-feedback-confirmation>
        </ng-container>
    </div>
</ng-container>
