export class Time {

    public hours: number
    public minutes: number

    constructor(time: string | null, hours: number | null, minutes: number | null) {
        if (time !== null) {
            const components = time.split(':')
            this.hours = Number(components[0])
            this.minutes = Number(components[1])
        } else {
            this.hours = hours ?? 0
            this.minutes = minutes ?? 0
        }
    }

    equals(other: Time): boolean {
        return this.hours === other.hours &&
            this.minutes === other.minutes
    }

    equalsTimeOfDate(date: Date): boolean {
        return this.hours === date.getHours() &&
            this.minutes === date.getMinutes()
    }

    dateTimeOnDate(date: Date): Date {
        const dateTime = new Date(date)
        dateTime.setHours(this.hours, this.minutes, 0, 0)
        return dateTime
    }

    toString(): string {
        const hours = this.hours.toString().padStart(2, '0')
        const minutes = this.minutes.toString().padStart(2, '0')
        return `${hours}:${minutes}`
    }
}
