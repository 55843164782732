import { ActivatedRoute } from '@angular/router'
import { BookingDetails } from '../domain/BookingDetails'
import { BookingDetailsViewModel } from '../booking-summary/BookingDetailsViewModel'
import { BookingService } from '../services/booking.service'
import { Component, OnInit } from '@angular/core'
import { Observable, filter, map, mergeMap } from 'rxjs'
import { catchError } from 'rxjs/operators'

@Component({
    selector: 'app-booking-reminder',
    templateUrl: './booking-reminder.component.html',
})
export class BookingReminderComponent implements OnInit {

    viewModel: Observable<BookingDetailsViewModel>

    constructor(
        route: ActivatedRoute,
        bookingService: BookingService
    ) {
        this.viewModel = route.queryParamMap.pipe(
            filter(params => params.get('action') === 'confirm'),
            mergeMap(_ => route.params),
            mergeMap(params => {
                const bookingId = params['bookingId']
                const reminderId = params['reminderId']
                return bookingService.confirmBookingReminder(bookingId, reminderId)
            }),
            catchError(error => {
                if (error.status !== 400) {
                    throw error
                }
                const bookingId = route.snapshot.params['bookingId']
                return bookingService.getBookingDetails(bookingId)
            }),
            map(details => this.makeViewModel(details))
        )
    }

    ngOnInit() { }

    private makeViewModel(details: BookingDetails): BookingDetailsViewModel {
        return new BookingDetailsViewModel(details, false, true)
    }
}
