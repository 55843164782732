import { BookingReason } from './BookingReason'
import { BookingSlot } from './BookingSlot'
import { BookingSlotOption } from './BookingSlotOption'
import { Event } from './Event'

export class BookingSlotAvailability {
    constructor(
        public bookingSlot: BookingSlot,
        public options: BookingSlotOption[]
    ) { }

    isReservableWithConfiguration(
        partySize: number,
        reason: BookingReason | null,
        event: Event | null
    ): boolean {
        return this.options.some(option => {
            return option.isReservableWithConfiguration(partySize, reason, event)
        })
    }

    reservableOptionWithConfiguration(
        partySize: number,
        reason: BookingReason | null,
        event: Event | null,
        dateTime: Date
    ): BookingSlotOption | null {
        if (this.bookingSlot.dateTime.getTime() !== dateTime.getTime()) {
            return null
        }
        return this.options.find(option => {
            return option.isReservableWithConfiguration(partySize, reason, event)
        }) ?? null
    }
}
