import { Area } from './Area'
import { BookingReason } from './BookingReason'
import { BookingSlot } from './BookingSlot'
import { BookingSlotAvailability } from './BookingSlotAvailability'
import { BookingSlotOption } from './BookingSlotOption'
import { Event } from './Event'

export class AreaAvailability {

    constructor(
        public area: Area,
        public bookingSlots: BookingSlotAvailability[],
        public exceptionDescription: string | null
    ) { }

    earliestBookingSlot(): BookingSlot | null {
        return this.bookingSlots[0]?.bookingSlot ?? null
    }

    latestBookingSlot(): BookingSlot | null {
        return this.bookingSlots[this.bookingSlots.length - 1]?.bookingSlot ?? null
    }

    bookingSlotsAroundTime(time: Date): BookingSlotAvailability[] {
        const limitOfBookingSlots = 9
        return [...this.bookingSlots]
            .sort((a, b) => {
                return Math.abs(a.bookingSlot.dateTime.getTime() - time.getTime())
                - Math.abs(b.bookingSlot.dateTime.getTime() - time.getTime())
            })
            .filter((bookingSlot, index) => {
                return index < limitOfBookingSlots
            })
            .sort((a, b) => {
                return a.bookingSlot.dateTime.getTime() - b.bookingSlot.dateTime.getTime()
            })
    }

    bookingSlotsInsideCutOffTime(): BookingSlotAvailability[] {
        const cutOffTime = this.area.cutOffTime
        return this.bookingSlots.filter(bookingSlotAvailability => {
            return bookingSlotAvailability.bookingSlot.dateTime.getTime() < cutOffTime.getTime()
        })
    }

    hasBookingSlot(bookingSlot: BookingSlot): boolean {
        return this.bookingSlots.some(bookingSlotAvailability => {
            return bookingSlotAvailability.bookingSlot.dateTime.getTime() === bookingSlot.dateTime.getTime()
        })
    }

    get areaCanBeBooked(): boolean {
        if (!this.area.hasAReservableTable()) {
            return false
        }
        const scheduleAllowsForABooking = this.bookingSlots.find(bookingSlotAvailability => {
            return bookingSlotAvailability.options.length > 0
        }) !== undefined
        if (!scheduleAllowsForABooking) {
            return false
        }
        return true
    }

    isConfiguredForReason(
        reason: BookingReason
    ): boolean {
        return this.bookingSlots.some(bookingSlotAvailability => {
            return bookingSlotAvailability.options.some(option => {
                return option.reasonId === reason.id
            })
        })
    }

    isConfiguredForEvent(
        event: Event
    ): boolean {
        return this.bookingSlots.some(bookingSlotAvailability => {
            return bookingSlotAvailability.options.some(option => {
                return option.eventId === event.id
            })
        })
    }

    isReservableWithConfiguration(
        partySize: number,
        reason: BookingReason | null,
        event: Event | null
    ): boolean {
        return this.bookingSlots.find(bookingSlotAvailability => {
            return bookingSlotAvailability.isReservableWithConfiguration(partySize, reason, event)
        }) !== undefined
    }

    reservableOptionWithConfiguration(
        partySize: number,
        reason: BookingReason | null,
        event: Event | null,
        dateTime: Date
    ): BookingSlotOption | null {
        return this.bookingSlots
            .map(bookingSlotAvailability => {
                return bookingSlotAvailability.reservableOptionWithConfiguration(
                    partySize,
                    reason,
                    event,
                    dateTime
                )
            })
            .find(option => option !== null) ?? null
    }
}
