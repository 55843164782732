import { ActivatedRoute } from '@angular/router'
import { BookingDetails } from '../domain/BookingDetails'
import { BookingDetailsViewModel } from './BookingDetailsViewModel'
import { BookingService } from '../services/booking.service'
import { Component, OnDestroy, OnInit } from '@angular/core'
import {
    Observable,
    Subject, map, mergeMap, takeUntil,
} from 'rxjs'

@Component({
    selector: 'app-booking-summary',
    templateUrl: './booking-summary.component.html',
})
export class BookingSummaryComponent implements OnInit, OnDestroy {

    viewModel: Observable<BookingDetailsViewModel>
    private onDestroy$ = new Subject<boolean>()

    constructor(
        route: ActivatedRoute,
        bookingService: BookingService
    ) {
        const params = route.params
        this.viewModel = params.pipe(
            takeUntil(this.onDestroy$),
            mergeMap(params => {
                return bookingService.getBookingDetails(params['bookingId'])
                    .pipe(
                        map(details => this.makeViewModel(details))
                    )
            })
        )
    }

    ngOnInit() { }

    ngOnDestroy() {
        this.onDestroy$.next(true)
        this.onDestroy$.unsubscribe()
    }

    private makeViewModel(details: BookingDetails): BookingDetailsViewModel {
        return new BookingDetailsViewModel(details, false, false)
    }
}
