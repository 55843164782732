import { DatePipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'relativeDate',
})
export class RelativeDatePipe implements PipeTransform {

    constructor(
        private datePipe: DatePipe
    ) { }

    transform(date: Date | null, format?: string): string  | null {
        if (!date) {
            return null
        }
        const dateIsToday = date.toDateString() === new Date().toDateString()
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        const dateIsTomorrow = date.toDateString() === tomorrow.toDateString()
        if (dateIsToday) {
            return 'Today'
        }
        if (dateIsTomorrow) {
            return 'Tomorrow'
        }
        return this.datePipe.transform(date, format)
    }
}
