import { BookingFeedbackComponent } from './booking-feedback/booking-feedback.component'
import { BookingFormComponent } from './booking-form/booking-form.component'
import { BookingModalComponent } from './booking-modal/booking-modal.component'
import { BookingModalLauncherComponent } from './modal-launcher/booking-modal-launcher.component'
import { BookingReminderComponent } from './booking-reminder/booking-reminder.component'
import { BookingSummaryComponent } from './booking-summary/booking-summary.component'
import { ModifyBookingComponent } from './modify-booking/modify-booking.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
    {
        path: 'organisation/:organisationId/business/:businessId/venue/:venueId',
        component: BookingFormComponent,
    },
    {
        path: 'organisation/:organisationId/business/:businessId/venue/:venueId/booking/:bookingId/modify',
        component: ModifyBookingComponent,
    },
    {
        path: 'organisation/:organisationId/business/:businessId/venue/:venueId/modal',
        component: BookingModalComponent,
    },
    {
        path: 'booking/:bookingId/reminder/:reminderId',
        component: BookingReminderComponent,
    },
    {
        path: 'booking/:bookingId/feedback-request/:feedbackRequestId',
        component: BookingFeedbackComponent,
    },
    {
        path: 'booking/:bookingId',
        component: BookingSummaryComponent,
    },
    {
        path: 'organisation/:organisationId/business/:businessId/venue/:venueId/launcher',
        component: BookingModalLauncherComponent,
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes, {
                bindToComponentInputs: true,
            }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
