import { AddressDTO } from './AddressDTO'
import { BookingStatusTypeDTO } from './BookingStatusTypeDTO'

export class BookingDetailsDTO {
    constructor(
        public bookingId: string,
        public currencyCode: string | null,
        public venueId: string,
        public venueName: string,
        public venueAddress: AddressDTO,
        public venueDiningInformation: string | null,
        public venuePhoneNumber: string,
        public bookingName: string,
        public partySize: number,
        public durationMinutes: number,
        public dateTime: string,
        public statusType: BookingStatusTypeDTO,
        public cancellationChargeAmount: number | null,
        public cancellationChargeCutOffTime: Date | null,
        public areaId: string,
        public areaName: string | null,
        public reasonId: string | null,
        public reasonName: string | null,
        public reasonDiningInformation: string | null,
        public eventId: string | null,
        public eventName: string | null,
        public eventImageUrl: string | null,
        public depositAmount: number | null,
        public depositCurrencyCode: string | null,
        public depositDatePaid: string | null,
        public depositDateRefunded: string | null,
        public depositRefundCutOffTime: string | null,
        public requiresWheelchairAccess: boolean,
        public modifyBookingPath: string | null,
        public pendingPaymentExpiryDateTime: string | null
    ) { }
}
