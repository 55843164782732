import { Component, forwardRef, OnInit } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms'
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validator } from '@angular/forms'

@Component({
    selector: 'app-day-of-year-input',
    templateUrl: './day-of-year-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DayOfYearInputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DayOfYearInputComponent),
            multi: true,
        }
    ],
})
export class DayOfYearInputComponent implements ControlValueAccessor, Validator, OnInit {
    form: FormGroup
    months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]
    days: string[] = []
    private onChange = (_: string | null) => {}
    private onTouched = () => {}
    private onValidatorChange = () => {}
    // Use a leap year to calculate the number of days in a month
    // so that we can validate the day input
    private yearWithLeapDay = 2020

    constructor(private fb: FormBuilder) {
        this.form = this.fb.group({
            month: [''],
            day: [''],
        })
    }

    ngOnInit() {
        this.days = Array.from(
            { length: 31 },
            (_, i) => (i + 1).toString().padStart(2, '0')
        )
        this.form.valueChanges.subscribe(() => {
            this.emitValue()
            this.onValidatorChange()
        })
    }

    writeValue(value: string) {
        if (!value) {
            this.form.reset()
            return
        }
        const [month, day] = value.split('/')
        this.form.setValue({
            month: month.padStart(2, '0'),
            day: day.padStart(2, '0'),
        }, { emitEvent: false })
    }

    registerOnChange(fn: any) {
        this.onChange = fn
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn
    }

    setDisabledState?(isDisabled: boolean) {
        if (isDisabled) {
            this.form.disable()
        } else {
            this.form.enable()
        }
    }

    validate(_: AbstractControl): ValidationErrors | null {
        const month = this.form.get('month')?.value
        const day = this.form.get('day')?.value
        if (month && !day) {
            return { dayRequired: true }
        }
        if (month && day) {
            const monthNumber = parseInt(month, 10)
            const dayNumber = parseInt(day, 10)
            const daysInMonth = (month: number, year: number) => {
                return new Date(year, month, 0).getDate()
            }
            const maxDay = daysInMonth(monthNumber, this.yearWithLeapDay)
            if (dayNumber < 1 || dayNumber > maxDay) {
                return { invalidDate: true }
            }
        }
        return null
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onValidatorChange = fn
    }

    private emitValue() {
        const month = this.form.get('month')?.value
        const day = this.form.get('day')?.value
        if (month && day) {
            this.onChange(`${month}/${day}`)
        } else {
            this.onChange(null)
        }
    }

    onBlur() {
        this.onTouched()
    }
}
