export class BookingSlot {
    dateTime: Date

    constructor(dateTime: Date) {
        this.dateTime = dateTime
    }

    toLocalISOFormattedDateTimeString(): string {
        let offsetMs = this.dateTime.getTimezoneOffset() * 60 * 1000
        let msLocal =  this.dateTime.getTime() - offsetMs
        let dateLocal = new Date(msLocal)
        let iso = dateLocal.toISOString()
        return iso
    }

    equals(other: BookingSlot): boolean {
        return this.dateTime.getTime() === other.dateTime.getTime()
    }

    endDateTime(durationMinutes: number): Date {
        return new Date(this.dateTime.getTime() + durationMinutes * 60 * 1000)
    }
}
