import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms'

@Component({
    selector: 'app-accessibility-form',
    templateUrl: './accessibility-form.component.html',
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
        },
    ],
})
export class AccessibilityFormComponent implements OnInit {

    @Input() isLoading!: boolean
    @Output() restrictionsSelected = new EventEmitter<void>()
    form!: FormGroup

    constructor(
        private rootFormGroup: FormGroupDirective
    ) { }

    ngOnInit() {
        this.form = this.rootFormGroup.form
    }

    next() {
        const wheelchairAccess = this.form.get('requiresWheelchairAccess')
        if (wheelchairAccess?.value === null) {
            wheelchairAccess.setValue(false)
        }
        this.restrictionsSelected.emit()
    }
}
