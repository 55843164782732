import { AddressDTO } from '../services/DTOs/AddressDTO'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'addressString',
})
export class AddressPipe implements PipeTransform {
    transform(address?: AddressDTO): any {
        let addressString = address ? Object.values(address).filter(Boolean).join(', ') : ''
        return addressString !== '' ? addressString : 'None'
    }
}
