import { FormArray, FormGroup } from '@angular/forms'

export function markAllFormControlsAsTouched(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
        form.controls[key].markAsTouched({
            onlySelf: true,
        })
        if (form.controls[ key ] instanceof FormArray) {
            const formArray = form.controls[key] as FormArray
            formArray.controls.forEach(control => {
                if (control instanceof FormGroup) {
                    markAllFormControlsAsTouched(control)
                }
            })
        }
    })
}
