import { BookingDetails } from '../domain/BookingDetails'
import { BookingDetailsDTO } from './DTOs/BookingDetailsDTO'
import { BookingFeedback } from '../domain/BookingFeedback'
import { BookingRequest } from '../domain/BookingRequest'
import { Context } from '../domain/Context'
import { DTOAdapter } from './DTOs/DTOAdapter'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, map } from 'rxjs'
import { compare } from 'fast-json-patch'
import { environment } from 'projects/booking/src/environments/environment'
import { v4 as uuidv4 } from 'uuid'

@Injectable({
    providedIn: 'root',
})
export class BookingService {

    private baseURL = environment.apiBaseUrl

    constructor(
        private http: HttpClient,
        private dtoAdapter: DTOAdapter
    ) { }

    getBookingDetails(
        bookingId: string
    ): Observable<BookingDetails> {
        const path = `
/booking/details/${encodeURIComponent(bookingId)}`
        const url = new URL(path, this.baseURL)
        return this.http.get<BookingDetailsDTO>(url.toString())
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDetailsDto(dto))
            )
    }

    requestBooking(
        context: Context,
        areaId: string,
        bookingRequest: BookingRequest
    ): Observable<BookingDetails> {
        let path = `
/booking\
/organisation/${encodeURIComponent(context.organisationId)}\
/business/${encodeURIComponent(context.businessId)}\
/venue/${encodeURIComponent(context.venueId)}\
/area/${encodeURIComponent(areaId)}\
/booking`
        const dto = this.dtoAdapter.adaptBookingRequest(bookingRequest)
        let url = new URL(path, this.baseURL)
        const headers = {
            'Idempotency-Key': uuidv4(),
        }
        return this.http.post<BookingDetailsDTO>(url.toString(), dto, { headers })
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDetailsDto(dto))
            )
    }

    confirmBookingReminder(
        bookingId: string,
        reminderId: string
    ): Observable<BookingDetails> {
        const path = `
/booking/${encodeURIComponent(bookingId)}\
/reminder/${encodeURIComponent(reminderId)}\
/confirm`
        const url = new URL(path, this.baseURL)
        return this.http.put<BookingDetailsDTO>(url.toString(), null)
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDetailsDto(dto))
            )
    }

    confirmBooking(
        bookingId: string,
        cancellationChargePaymentMethodId: string | null,
        depositPaymentIntentId: string | null
    ): Observable<BookingDetails> {
        const path = '/booking' +
            `/${encodeURIComponent(bookingId)}` +
            '/confirm'
        const dto = {
            paymentMethodId: cancellationChargePaymentMethodId,
            depositPaymentIntentId: depositPaymentIntentId,
        }
        const headers = {
            'Idempotency-Key': uuidv4(),
        }
        const url = new URL(path, this.baseURL)
        return this.http.post<BookingDetailsDTO>(url.toString(), dto, { headers })
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDetailsDto(dto))
            )
    }

    modifyBooking(
        organisationId: string,
        businessId: string,
        venueId: string,
        bookingDetails: BookingDetails,
        updatedBookingDetails: BookingDetails
    ): Observable<BookingDetails> {
        const path = `
/booking\
/organisation/${encodeURIComponent(organisationId)}\
/business/${encodeURIComponent(businessId)}\
/venue/${encodeURIComponent(venueId)}\
/booking/${encodeURIComponent(bookingDetails.bookingId)}`
        const dto = this.dtoAdapter.adaptBookingDetails(bookingDetails)
        const updatedDto = this.dtoAdapter.adaptBookingDetails(updatedBookingDetails)
        const patch = compare(dto, updatedDto)
        const url = new URL(path, this.baseURL)
        const headers = {
            'Content-Type': 'application/json-patch+json',
        }
        return this.http.patch<BookingDetailsDTO>(url.toString(), patch, { headers })
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDetailsDto(dto))
            )
    }

    cancelBooking(
        bookingId: string
    ): Observable<BookingDetails> {
        const path = `
/booking/${encodeURIComponent(bookingId)}\
/cancel`
        const url = new URL(path, this.baseURL)
        const headers = {
            'Idempotency-Key': uuidv4(),
        }
        return this.http.put<BookingDetailsDTO>(url.toString(), null, { headers })
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDetailsDto(dto))
            )
    }

    submitFeedback(
        bookingId: string,
        feedback: BookingFeedback
    ) {
        const path = `/booking/${encodeURIComponent(bookingId)}/feedback`
        const url = new URL(path, this.baseURL)
        const dto = this.dtoAdapter.adaptBookingFeedback(feedback)
        return this.http.post(url.toString(), dto)
    }
}
