export class Address {
    constructor(
        public addressLineOne: string,
        public addressLineTwo: string | null,
        public city: string,
        public county: string | null,
        public postCode: string,
        public country: string
    ) { }

    lines(): string {
        return new Array (
            this.addressLineOne,
            this.addressLineTwo,
            this.city,
            this.country,
            this.postCode,
            this.country
        )
            .filter((addressLine): addressLine is string => {
                return addressLine !== null
            })
            .join('\n')
    }
}
