import { ReasonDateRangeFilter } from './ReasonDateRangeFilter'
import { ReasonScheduleRule } from './ReasonScheduleRule'

export class BookingReason {
    constructor(
        public id: string,
        public displayName: string,
        public displayOrder: number,
        public description: string | null,
        public scheduleRules: ReasonScheduleRule[],
        public dateRangeFilters: ReasonDateRangeFilter[],
        public areaBookingOrder: string[] | null,
        public diningInformation: string | null
    ) { }

    usedOnDate(date: Date): boolean {
        const appliesToSchedule = this.scheduleRules
            .filter(rule => rule.period?.open ?? true)
            .some(rule => rule.usedOnDate(date))
        if (!appliesToSchedule) {
            return false
        }
        const restrictedByDateRange = this.dateRangeFilters.length > 0
        if (!restrictedByDateRange) {
            return true
        }
        const appliesToDateRange = this.dateRangeFilters
            .some(filter => filter.usedOnDate(date))
        if (!appliesToDateRange) {
            return false
        }
        return true
    }

    usedOnDateTime(dateTime: Date): boolean {
        if (!this.usedOnDate(dateTime)) {
            return false
        }
        if (this.scheduleRules.length === 0) {
            return true
        }
        const appliesToSchedule = this.scheduleRules
            .some(rule => rule.usedOnDateTime(dateTime))
        if (!appliesToSchedule) {
            return false
        }
        return true
    }
}
