<div class="d-flex flex-column align-content-center justify-content-center">
    <div class="alert alert-danger">
        <ng-container
            *ngIf="error"
        >
            {{ error }}
        </ng-container>
        <ng-container
            *ngIf="error === null"
        >
            Something went wrong
        </ng-container>
    </div>
</div>
