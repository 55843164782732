<div class="modal-header">
    <div class="flex-fill hstack gap-2 justify-content-between">
        <h5 class="modal-title fw-semibold">
            <ng-container
                *ngIf="bookingDetails.cancellationChargeAmount !== null"
            >
                Card Details
            </ng-container>
            <ng-container
                *ngIf="bookingDetails.depositAmount !== null"
            >
                Pay Deposit
            </ng-container>
        </h5>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="dismiss()"
        >
        </button>
    </div>
</div>
<div class="modal-body">
    <app-spinner-overlay
        *ngIf="isLoadingVenue"
    >
    </app-spinner-overlay>
    <app-spinner-overlay
        *ngIf="isConfirmingPayment"
    >
    </app-spinner-overlay>
    <app-error
        *ngIf="errorLoadingVenue"
        [error]="'An error occurred while loading your booking. Please try again.'"
    >
    </app-error>
    <app-error
        *ngIf="errorConfirmingPayment"
        [error]="'An error occurred while confirming your payment. Please try again.'"
    >
    </app-error>
    <app-payment-details-form
        *ngIf="venue && bookingDetails.cancellationChargeAmount !== null"
        [showTitle]="false"
        [showBackButton]="false"
        [venue]="venue"
        [bookingDetails]="bookingDetails"
        [formGroup]="form"
        (paymentStarted)="confirmWithCardStarted()"
    >
    </app-payment-details-form>
    <app-deposit-form
        *ngIf="venue && bookingDetails.depositAmount !== null"
        [showTitle]="false"
        [showBackButton]="false"
        [venue]="venue"
        [bookingDetails]="bookingDetails"
        [formGroup]="form"
        (paymentStarted)="confirmWithCardStarted()"
    >
    </app-deposit-form>
</div>
