import { BookingDetails } from '../domain/BookingDetails'
import { DTOAdapter } from './DTOs/DTOAdapter'
import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class ModelCloningService {

    constructor(
        private dtoAdapter: DTOAdapter
    ) { }

    cloneBookingDetails(bookingDetails: BookingDetails): BookingDetails {
        const dto = this.dtoAdapter.adaptBookingDetails(bookingDetails)
        return this.dtoAdapter.adaptBookingDetailsDto(dto)
    }
}
