<div
    class="container col-sm-10 col-md-8 col-lg-6 col-xl-4 my-2 vstack gap-2"
    [formGroup]="form"
>
    <h4 class="fw-semibold m-0 px-3">
        Modify Booking
    </h4>
    <app-spinner-overlay
        [showSpinner]="isLoading"
    >
    </app-spinner-overlay>
    <app-modal-booking-form
        *ngIf="bookingDetails$ | async as bookingDetails"
        [organisationId]="organisationId"
        [businessId]="businessId"
        [venueId]="venueId"
        [bookingDetails]="bookingDetails"
        (saveRequested)="save($event[0], bookingDetails)"
    >
    </app-modal-booking-form>
</div>
