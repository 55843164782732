<div class="vstack gap-3">
    <label
        class="btn btn-outline-secondary p-2 d-flex flex-column justify-content-center align-items-center"
        data-test="wheelchair-access-select"
    >
        <div class="m-1 hstack gap-3 text-start">
            <input
                type="checkbox"
                class="form-check-input m-0"
                role="button"
                formControlName="requiresWheelchairAccess"
            >
            <div class="vstack align-items-start">
                    <span class="fw-semibold">
                        Wheelchair Access
                    </span>
                <span class="small lh-sm">
                    Please select if you require wheelchair access,
                    so we can ensure you are seated appropriately.
                </span>
            </div>
        </div>
    </label>
    <button
        class="btn btn-primary"
        type="button"
        (click)="next()"
        [disabled]="isLoading"
    >
        Next
    </button>
</div>
