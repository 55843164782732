import { Availability } from '../domain/Availability'
import { AvailabilityDTO } from './DTOs/AvailabilityDTO'
import { DTOAdapter } from './DTOs/DTOAdapter'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'projects/booking/src/environments/environment'
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class AvailabilityService {

    private baseURL = environment.apiBaseUrl

    constructor(
        private http: HttpClient,
        private dtoAdapter: DTOAdapter
    ) {}

    getAvailability(
        organisationId: string,
        businessId: string,
        venueId: string,
        startDate: Date,
        endDate: Date,
        updatingBookingId?: string,
        requiresWheelchairAccess?: boolean,
        restrictedReasonIds?: string[]
    ): Observable<Availability> {
        let startDateString = this.makeLocalISOFormattedDateString(startDate)
        let endDateString = this.makeLocalISOFormattedDateString(endDate)
        let params = new HttpParams()
            .set('startDate', startDateString)
            .set('endDate', endDateString)
        if (updatingBookingId) {
            params = params.set('updatingBookingId', updatingBookingId)
        }
        if (requiresWheelchairAccess !== undefined) {
            params = params.set('requiresWheelchairAccess', requiresWheelchairAccess.toString())
        }
        if (restrictedReasonIds) {
            params = params.set('restrictedReasonIds', restrictedReasonIds.join(','))
        }
        let path = `
/booking\
/organisation/${encodeURIComponent(organisationId)}\
/business/${encodeURIComponent(businessId)}\
/venue/${encodeURIComponent(venueId)}\
/booking-slot-availability\
?${params.toString()}
    `
        let url = new URL(path, this.baseURL)
        return this.http.get<AvailabilityDTO>(url.toString())
            .pipe(
                map(dto => this.dtoAdapter.adaptAvailabilityDto(dto))
            )
    }

    private makeLocalISOFormattedDateString(date: Date): string {
        let offsetMs = date.getTimezoneOffset() * 60 * 1000
        let msLocal =  date.getTime() - offsetMs
        let dateLocal = new Date(msLocal)
        let iso = dateLocal.toISOString()
        let [isoLocal] = iso.split('T')
        return isoLocal
    }
}
