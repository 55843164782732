<app-spinner-overlay [showSpinner]="isSendingFeedback">
    <div class="container col-sm-10 col-md-8 col-lg-6 col-xl-4 my-2">
        <form
            [formGroup]="feedbackForm"
            class="vstack gap-3"
        >
            <div class="vstack gap-2">
                <h5>Rate your experience</h5>
                <label
                    *ngIf="feedbackForm.get('rating')?.value"
                >
                    <span>Your rating: </span>
                    <span>
                        {{ feedbackForm.get('rating')?.value | ratingName }}
                    </span>
                </label>
                <div
                    class="d-flex flex-column flex-sm-row flex-sm-row-reverse justify-content-center gap-2"
                    role="group"
                >
                    <div>
                        <input
                            type="radio"
                            class="btn-check"
                            formControlName="rating"
                            id="btn-excellent"
                            autocomplete="off"
                            [value]="5"
                        >
                        <label class="btn btn-outline-excellent" for="btn-excellent">
                            {{ 5 | ratingName }}
                        </label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            class="btn-check"
                            formControlName="rating"
                            id="btn-good"
                            autocomplete="off"
                            [value]="4"
                        >
                        <label class="btn btn-outline-good" for="btn-good">
                            {{ 4 | ratingName }}
                        </label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            class="btn-check"
                            formControlName="rating"
                            id="btn-fair"
                            autocomplete="off"
                            [value]="3"
                        >
                        <label class="btn btn-outline-fair" for="btn-fair">
                            {{ 3 | ratingName }}
                        </label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            class="btn-check"
                            formControlName="rating"
                            id="btn-poor"
                            autocomplete="off"
                            [value]="2"
                        >
                        <label class="btn btn-outline-poor" for="btn-poor">
                            {{ 2 | ratingName }}
                        </label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            class="btn-check"
                            formControlName="rating"
                            id="btn-very-poor"
                            autocomplete="off"
                            [value]="1"
                        >
                        <label class="btn btn-outline-very-poor" for="btn-very-poor">
                            {{ 1 | ratingName }}
                        </label>
                    </div>
                </div>
                <div>
                    <label for="comment" class="form-label">Leave us a comment (optional)</label>
                    <textarea
                        class="form-control"
                        placeholder="Tells us more about your experience"
                        id="comment"
                        formControlName="comment"
                        rows="3"
                    >
                    </textarea>
                </div>
            </div>
            <div class="row justify-content-end">
                    <div class="col-auto">
                        <button
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="!feedbackForm.valid"
                            (click)="onSubmitFeedback()"
                        >
                            Submit
                        </button>
                    </div>
                </div>
        </form>
    </div>
</app-spinner-overlay>
