<div
    [formGroup]="form"
    [ngSwitch]="step"
    appVenueColour
    [primaryColour]="primaryColour"
>
    <app-spinner-overlay
        [showSpinner]="isLoadingAvailability"
    >
    </app-spinner-overlay>
    <ng-container
        *ngIf="availability | async as availability"
    >
        <app-booking-slot
            *ngSwitchCase="formStep.BookingSlot"
            [availability]="availability"
        >
        </app-booking-slot>
        <app-booking-details-form
            *ngSwitchCase="formStep.BookingDetails"
            (backSelected)="goBackFromBookingDetails()"
            (nextSelected)="goNextFromBookingDetails()"
            (bookSelected)="bookButtonSelected()"
        >
        </app-booking-details-form>
    </ng-container>
    <ng-container
        *ngSwitchCase="formStep.PaymentDetails"
    >
        <div class="container col-sm-10 col-md-8 col-lg-6 col-xl-4 my-2">
            <app-payment-details-form
                [showTitle]="true"
                [showBackButton]="true"
                (backSelected)="goBackFromPaymentDetails()"
                (bookSelected)="bookButtonSelected()"
            >
            </app-payment-details-form>
        </div>
    </ng-container>
    <ng-container
        *ngSwitchCase="formStep.Deposit"
    >
        <div class="container col-sm-10 col-md-8 col-lg-6 col-xl-4 my-2">
            <app-deposit-form
                [showTitle]="true"
                [showBackButton]="true"
                (backSelected)="goBackFromDeposit()"
                (bookSelected)="bookButtonSelected()"
            >
            </app-deposit-form>
        </div>
    </ng-container>
    <ng-container
        *ngSwitchCase="formStep.Confirmation"
    >
        <app-confirmation
            *ngIf="bookingDetails !== null"
            [details]="bookingDetails"
            (bookAgainSelected)="bookAgainSelected()"
        >
        </app-confirmation>
    </ng-container>
</div>
